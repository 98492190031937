import { Dialog } from "@mui/material";
import { Modal } from "antd";

interface IProps {
  open: boolean;
  onClose: () => void;
  title: string;
  heading?: string;
  subtitle: string;
  onProceed: () => void;
  hideWarnIcon?: boolean;
}

export default function ConfirmationDialog({
  open,
  onClose,
  title,
  heading,
  subtitle,
  onProceed,
  hideWarnIcon,
}: IProps) {
  return (
    <Modal
      title={title}
      open={open}
      onCancel={onClose}
      onOk={onProceed}
      okButtonProps={{ danger: true }}
      okText={"Yes"}
      cancelText={"No"}
    >
      <div className="p-20">
        <h2 className="divCenterAlign">{heading ?? title} </h2>
        <div className="divAlignItemsOnly">
          {!hideWarnIcon && (
            <img
              src={"/assets/icons/warning.png"}
              className="iconSizeLarge"
              alt="warning"
            />
          )}

          <p style={{ margin: "0px 20px" }}>{subtitle}</p>
        </div>
      </div>
    </Modal>
  );
}
