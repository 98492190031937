export function ComparisonUSA() {
  return (
    <div>
      <table
        style={{
          textAlign: "left",
          width: "100%",
          border: "1px solid lightgrey",
          borderRadius: 10,
        }}
        cellPadding={"20px"}
      >
        <thead>
          <tr style={{ fontSize: 20 }}>
            <th></th>
            <th>Others</th>
            <th>
              <strong>
                Expense<strong style={{ color: "yellow" }}>Hut</strong>
              </strong>
            </th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td style={{ textAlign: "left" }}>POS Hardware</td>
            <td style={{ textAlign: "left" }}>$1400 </td>
            <td style={{ textAlign: "left" }}>$0</td>
          </tr>
          <tr>
            <td style={{ textAlign: "left" }}>Monthly Subscription</td>
            <td style={{ textAlign: "left" }}>$75</td>
            <td style={{ textAlign: "left" }}>$99</td>
          </tr>
          <tr>
            <td style={{ textAlign: "left" }}>Additonal Device</td>
            <td style={{ textAlign: "left" }}>$35</td>
            <td style={{ textAlign: "left" }}>$0</td>
          </tr>
          <tr>
            <td style={{ textAlign: "left" }}>Additonal User</td>
            <td style={{ textAlign: "left" }}>$10</td>
            <td style={{ textAlign: "left" }}>$0</td>
          </tr>

          <tr>
            <td style={{ textAlign: "left" }}>
              Estd Monthly Spending <sup>1</sup>
            </td>
            <td style={{ textAlign: "left" }}>$210</td>
            <td style={{ textAlign: "left" }}>
              <span style={{ color: "lime" }}>$99</span>
            </td>
          </tr>

          <tr>
            <td style={{ textAlign: "left" }}>Transaction Fee</td>
            <td style={{ textAlign: "left" }}>2.6%</td>
            <td style={{ textAlign: "left" }}>2.6% </td>
          </tr>

          <tr>
            <td style={{ textAlign: "left" }}>Android & iOS</td>
            <td style={{ textAlign: "left" }}>
              <span>No</span>
            </td>
            <td style={{ textAlign: "left" }}>
              <span style={{ color: "lime" }}>Yes</span>
            </td>
          </tr>
          <tr>
            <td style={{ textAlign: "left" }}>Loyalty & Rewards</td>
            <td style={{ textAlign: "left" }}>
              <span>Extra</span>
            </td>
            <td style={{ textAlign: "left" }}>
              <span style={{ color: "lime" }}>FREE</span>
            </td>
          </tr>
          <tr>
            <td style={{ textAlign: "left" }}>Data Backup</td>
            <td style={{ textAlign: "left" }}>
              <span>Extra</span>
            </td>
            <td style={{ textAlign: "left" }}>
              <span style={{ color: "lime" }}>FREE</span>
            </td>
          </tr>

          <tr>
            <td style={{ textAlign: "left" }}>
              Estd 1st Month Spending <sup>1</sup>
            </td>
            <td style={{ textAlign: "left" }}>$1765</td>
            <td style={{ textAlign: "left" }}>
              <span style={{ color: "lime" }}>$294</span>
            </td>
          </tr>

          <tr>
            <td style={{ textAlign: "left" }}>Estd 1st Month Savings</td>
            <td style={{ textAlign: "left" }}>-</td>
            <td style={{ textAlign: "left" }}>
              <span style={{ color: "lime" }}>$1400</span>
            </td>
          </tr>

          <tr>
            <td style={{ textAlign: "left" }}>Estd 1st Year Spending</td>
            <td style={{ textAlign: "left" }}>$5285</td>
            <td style={{ textAlign: "left" }}>
              <span style={{ color: "lime" }}>$3528</span>
            </td>
          </tr>

          <tr>
            <td style={{ textAlign: "left" }}>Estd 1st Year Savings</td>
            <td style={{ textAlign: "left" }}>-</td>
            <td style={{ textAlign: "left" }}>
              <span style={{ color: "lime" }}>$1757</span>
            </td>
          </tr>
        </tbody>
      </table>

      <div
        className="my-20 mediumText"
        style={{ color: "var(--exhut-light-grey0)" }}
      >
        <span>
          1. Calculated based on estimated of 500 orders per month each $15.
          Estimated employee count 5.
        </span>
      </div>
    </div>
  );
}

export function ComparisonIndia() {
  return (
    <div>
      <table
        style={{
          textAlign: "left",
          width: "100%",
          border: "1px solid lightgrey",
          borderRadius: 10,
        }}
        cellPadding={"20px"}
      >
        <thead>
          <tr style={{ fontSize: 20 }}>
            <th></th>
            <th>Others</th>
            <th>
              <strong>
                Expense<strong style={{ color: "yellow" }}>Hut</strong>
              </strong>
            </th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td style={{ textAlign: "left" }}>POS Hardware</td>
            <td style={{ textAlign: "left" }}>₹25000</td>
            <td style={{ textAlign: "left" }}>₹0</td>
          </tr>
          <tr>
            <td style={{ textAlign: "left" }}>Monthly Subscription</td>
            <td style={{ textAlign: "left" }}>₹750</td>
            <td style={{ textAlign: "left" }}>$4500</td>
          </tr>

          <tr>
            <td style={{ textAlign: "left" }}>Android & iOS</td>
            <td style={{ textAlign: "left" }}>
              <span>No</span>
            </td>
            <td style={{ textAlign: "left" }}>
              <span style={{ color: "lime" }}>Yes</span>
            </td>
          </tr>
          <tr>
            <td style={{ textAlign: "left" }}>SMS Rewards</td>
            <td style={{ textAlign: "left" }}>
              <span>Extra</span>
            </td>
            <td style={{ textAlign: "left" }}>
              <span style={{ color: "lime" }}>FREE</span>
            </td>
          </tr>
          <tr>
            <td style={{ textAlign: "left" }}>Repeat Customers - Rewards </td>
            <td style={{ textAlign: "left" }}>100%</td>
            <td style={{ textAlign: "left" }}>150%</td>
          </tr>
          <tr>
            <td style={{ textAlign: "left" }}>Buzz</td>
            <td style={{ textAlign: "left" }}>
              <span>-</span>
            </td>
            <td style={{ textAlign: "left" }}>
              <span style={{ color: "lime" }}>FREE</span>
            </td>
          </tr>
          <tr>
            <td style={{ textAlign: "left" }}>Repeat Customers - Buzz </td>
            <td style={{ textAlign: "left" }}>100%</td>
            <td style={{ textAlign: "left" }}>170%</td>
          </tr>

          <tr>
            <td style={{ textAlign: "left", width: 150 }}>
              Estimated Monthly Sales Increase <sup>1</sup>
            </td>
            <td style={{ textAlign: "left" }}>50%</td>
            <td style={{ textAlign: "left" }}>75%</td>
          </tr>
        </tbody>
      </table>

      <div
        className="my-20 mediumText"
        style={{ color: "var(--exhut-light-grey0)" }}
      >
        <span>
          1. Calculated based on estimated number of repeat customers with
          Rewards and Buzz.
        </span>
      </div>
    </div>
  );
}
