import { EyeInvisibleOutlined } from "@ant-design/icons";
import { Alert, Button, Flex, Input } from "antd";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import { useAuthenticateWithSSO, useRegisterMutation } from "../api/api";
import ErrorPanel from "../components/error.panel.component";
import { useLayoutContext } from "../providers/layout.provider";
import { IStatusMsg } from "../types/types";
import { getRecaptchaKey, isRecaptchaEnabled } from "../utils/app";
import {
  EXHUT_HUB_LOCAL_STORAGE_TOKEN,
  EXHUT_REDIRECT,
  ROUTES,
} from "../utils/constants";
import { downloadAndLoadGSIScript } from "../utils/lib.helper";

export default function RegisterPage() {
  const { isMobile } = useLayoutContext();
  const { mutateAsync: loginWithSSO } = useAuthenticateWithSSO();
  const { mutateAsync: registerAsync, isLoading: isRegisterLoading } =
    useRegisterMutation();
  const [showPasswordMode, setShowPasswordMode] = useState<boolean>(false);

  const navigate = useNavigate();
  const [statusMsg, setStatusMsg] = useState<IStatusMsg | undefined>();
  const [registerSuccess, setRegisterSuccess] = useState<boolean>(false);
  const [recaptchaVerified, setRecaptchaVerified] = useState(
    !isRecaptchaEnabled
  );

  const [searchParams, setSearchParams] = useSearchParams();

  const isLogout = new URLSearchParams(searchParams).get("logout");

  useEffect(() => {
    if (isLogout) {
      searchParams.delete("logout");
      setSearchParams(searchParams);

      setStatusMsg({ type: "error", text: "Please sign in." });
    }
  }, [isLogout]);

  useEffect(() => {
    downloadAndLoadGSIScript().then(() => {
      (window as any).google.accounts.id.initialize({
        client_id: process.env.REACT_APP_EXHUT_GOOGLE_CLIENT_ID,
        callback: (response: any) => {
          loginWithSSO({ token: response.credential, type: "google" })
            .then((resp: any) => {
              localStorage.setItem(EXHUT_HUB_LOCAL_STORAGE_TOKEN, resp.sign);
              const redirect = localStorage.getItem(EXHUT_REDIRECT);
              localStorage.removeItem(EXHUT_REDIRECT);
              navigate(redirect ?? ROUTES.HOME);
            })
            .catch(() => {
              setStatusMsg({
                type: "error",
                text: "Please use your email to signin.",
              });
            });
        },
      });
      (window as any).google.accounts.id.renderButton(
        document.querySelector(".sso-signin"),
        { theme: "dark", size: "medium" } // customization attributes
      );
    });
  }, [loginWithSSO]);

  const formik = useFormik({
    initialValues: {
      password: "",
      email: "",
    },
    validationSchema: Yup.object({
      password: Yup.string().required("Please enter password."),
      email: Yup.string()
        .matches(
          /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/,
          "Please enter valid email address"
        )
        .required("Please enter email."),
    }),
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: async (values) => {
      localStorage.setItem(EXHUT_REDIRECT, "/login");
      registerAsync({
        email: values.email.toLowerCase(),
        password: values.password,
      })
        .then(() => {
          setRegisterSuccess(true);
          setStatusMsg({
            type: "success",
            text: "We have emailed you a verification link to your email. Please use it to proceed",
          });
        })
        .catch((err) => {
          setStatusMsg({
            type: "error",
            text: err.response.data.message ?? "Whoops! Something went wrong.",
          });
        });
    },
  });

  useEffect(() => {
    if (!!statusMsg) {
      setStatusMsg(undefined);
    }
  }, [formik.values]);

  const setLoginReady = (e: any) => {
    // console.log("Ready recaptch", )
    setRecaptchaVerified(true);
  };

  return (
    <div className="body">
      <div className={``}>
        <div className="divRight mx-80 my-20">
          <Link to="/login">Login</Link>
        </div>
        <Header />

        <div className="divCenterAlign mb-20" style={{ height: 50 }}>
          {statusMsg && (
            <Alert message={statusMsg?.text} type={statusMsg?.type} />
          )}
        </div>

        {!!statusMsg && <div className="divCenterAlign"></div>}

        {!registerSuccess && (
          <form
            action="submit"
            onSubmit={formik.handleSubmit}
            className="divColumn divCenterAlign px-20 pb-40"
          >
            <>
              <Flex vertical gap={40}>
                <Flex vertical>
                  <Input
                    {...formik.getFieldProps("email")}
                    placeholder="email..."
                    id="email"
                    size="large"
                    style={{ width: 300 }}
                  />
                  {!!formik.errors.email && (
                    <ErrorPanel message={formik.errors.email} />
                  )}
                </Flex>

                <Input.Password
                  {...formik.getFieldProps("password")}
                  id="password"
                  status={!!formik.errors.password ? "error" : undefined}
                  placeholder="password..."
                  iconRender={(visible) =>
                    visible ? (
                      <EyeInvisibleOutlined />
                    ) : (
                      <EyeInvisibleOutlined />
                    )
                  }
                  size="large"
                />
              </Flex>

              {isRecaptchaEnabled && (
                <div style={{}} className="my-20">
                  <ReCAPTCHA
                    sitekey={getRecaptchaKey()}
                    onChange={setLoginReady}
                  />
                </div>
              )}

              <div style={{}} className="mx-20 mt-40">
                <Button
                  type="primary"
                  disabled={!recaptchaVerified}
                  loading={isRegisterLoading}
                  value={"Signup"}
                  onClick={formik.submitForm}
                >
                  Signup
                </Button>
              </div>
            </>
          </form>
        )}

        <div className="divCenterAlign m-20">
          <span className="sso-signin "></span>
        </div>

        <div className="divCenterAlign my-20">
          <Link to={ROUTES.login}> Already have an account? </Link>
        </div>
      </div>
    </div>
  );
}

const Header = () => {
  return (
    <div className={`divCenterAlign mt-40 mb-0 p-20`}>
      <div className="divCenterAlign mb-0">
        <h1>Great products are driven by customers like you!</h1>
      </div>
    </div>
  );
};

const styles = {
  formRowMin: {
    display: "flex",
    margin: "0px 10px",
  },
  formRow: {
    marginTop: 30,
    display: "flex",
  },
  formColumn: {
    marginTop: 30,
    display: "flex",
    flexDirection: "column",
  },
};
