import { EyeInvisibleOutlined } from "@ant-design/icons";
import { Button, Flex, Input } from "antd";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import { useAuthenticate, useAuthenticateWithSSO } from "../api/api";
import { useMessage } from "../components/notifications/message";
import { useLayoutContext } from "../providers/layout.provider";
import { getRecaptchaKey, isRecaptchaEnabled } from "../utils/app";
import {
  EXHUT_HUB_LOCAL_STORAGE_TOKEN,
  EXHUT_REDIRECT,
  ROUTES,
} from "../utils/constants";
import { downloadAndLoadGSIScript } from "../utils/lib.helper";

export default function RegisterPage() {
  const { isMobile } = useLayoutContext();
  const { mutateAsync: loginAsync, isLoading, isError } = useAuthenticate();
  const { mutateAsync: loginWithSSO } = useAuthenticateWithSSO();

  const navigate = useNavigate();
  const [recaptchaVerified, setRecaptchaVerified] = useState(
    !isRecaptchaEnabled
  );

  const [searchParams, setSearchParams] = useSearchParams();

  const isLogout = new URLSearchParams(searchParams).get("logout");

  const { notification, messageError } = useMessage();

  useEffect(() => {
    if (isLogout) {
      searchParams.delete("logout");
      setSearchParams(searchParams);
      messageError("Please sign in.");
    }
  }, [isLogout]);

  useEffect(() => {
    downloadAndLoadGSIScript().then(() => {
      (window as any).google.accounts.id.initialize({
        client_id: process.env.REACT_APP_EXHUT_GOOGLE_CLIENT_ID,
        callback: (response: any) => {
          loginWithSSO({ token: response.credential, type: "google" })
            .then((resp: any) => {
              localStorage.setItem(EXHUT_HUB_LOCAL_STORAGE_TOKEN, resp.sign);
              const redirect = localStorage.getItem(EXHUT_REDIRECT);
              localStorage.removeItem(EXHUT_REDIRECT);
              navigate(redirect ?? ROUTES.HOME);
            })
            .catch(() => {
              messageError("Please use your email to signin.");
            });
        },
      });
      (window as any).google.accounts.id.renderButton(
        document.querySelector(".sso-signin"),
        { theme: "dark", size: "medium" } // customization attributes
      );
    });
  }, [loginWithSSO]);

  const formik = useFormik({
    initialValues: {
      password: "",
      email: "",
    },
    validationSchema: Yup.object({
      password: Yup.string().required("Please enter password."),
      email: Yup.string().required("Please enter email"),
    }),
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: async (values) => {
      loginAsync({
        email: values.email.toLowerCase(),
        password: values.password,
      })
        .then((resp) => {
          localStorage.setItem(EXHUT_HUB_LOCAL_STORAGE_TOKEN, resp.sign);
          const redirect = localStorage.getItem(EXHUT_REDIRECT);
          localStorage.removeItem(EXHUT_REDIRECT);
          if (redirect && redirect !== "/login") {
            navigate(redirect);
          } else if (resp.admin === true) {
            navigate(ROUTES.ADMIN);
          } else {
            navigate(ROUTES.HOME);
          }
        })
        .catch((e) => {
          messageError(
            "The email or password entered does not match our records."
          );
        });
    },
  });

  const setLoginReady = (e: any) => {
    // console.log("Ready recaptch", )
    setRecaptchaVerified(true);
  };

  return (
    <div className="body">
      {notification}
      <div className={`mb-80 ${isMobile ? "mx-20" : "mx-80"}`}>
        <div className={isMobile ? "mt-40 divColumn" : "divFlex p-40"}>
          <div className="flex-1 divCenterAlign divColumn">
            <h2>We help achieve your goals</h2>

            <img src="/assets/rubiks.png" style={{ width: "50%" }} />
          </div>

          <div className="flex-1 divCenterAlign divColumn">
            <div className="my-40">
              <h2>Login</h2>
            </div>

            <form
              action="submit"
              onSubmit={formik.handleSubmit}
              className="divColumn pb-40"
            >
              <Flex vertical gap={40}>
                <Input
                  {...formik.getFieldProps("email")}
                  id="email"
                  placeholder="email..."
                  status={!!formik.errors.email ? "error" : undefined}
                  size="large"
                  style={{ width: 300 }}
                />

                <Input.Password
                  {...formik.getFieldProps("password")}
                  id="password"
                  placeholder="password..."
                  status={!!formik.errors.password ? "error" : undefined}
                  iconRender={(visible) =>
                    visible ? (
                      <EyeInvisibleOutlined />
                    ) : (
                      <EyeInvisibleOutlined />
                    )
                  }
                  style={{ width: 300 }}
                  size="large"
                />
              </Flex>

              <>
                {isRecaptchaEnabled && (
                  <div style={{}} className="mx-0 my-20">
                    <ReCAPTCHA
                      sitekey={getRecaptchaKey()}
                      onChange={setLoginReady}
                    />
                  </div>
                )}

                <div style={{}} className="my-40">
                  <Button
                    type="primary"
                    disabled={!recaptchaVerified}
                    onClick={formik.submitForm}
                    loading={isLoading}
                  >
                    Sign In
                  </Button>
                </div>
                <div className="divCenterAlign my-20">
                  <span className="sso-signin "></span>
                </div>

                <div className="divCenterAlign my-20">
                  <Link to={ROUTES.forgotPassword}>Forgot Password</Link>
                </div>
              </>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

const styles = {
  formRowMin: {
    display: "flex",
    margin: "5px 10px",
  },
  formRow: {
    marginTop: 30,
    display: "flex",
  },
  formColumn: {
    marginTop: 30,
    display: "flex",
    flexDirection: "column",
  },
};
