import { getCountryPhoneCode } from "./constants";

export function capitalize(input: string = "") {
  if (!input) return "";
  const words = input.split(" ");
  const result = [];
  for (const w of words) {
    result.push(`${w.charAt(0).toUpperCase()}${w.substring(1)}`);
  }
  return result.join(" ");
}

export function capitalizeWithTrunc(input: string = "") {
  const capitalized = capitalize(input);
  if (capitalized.length > 15) {
    return capitalized.substring(0, 14) + "...";
  }
  return capitalized;
}

export function transformPayload(obj: any) {
  const result: any = {};
  if (obj) {
    const keys = Object.keys(obj);
    for (const k of keys) {
      const camelToSnakeCase = k.replace(
        /[A-Z]/g,
        (letter) => `_${letter.toLowerCase()}`
      );
      result[camelToSnakeCase] = obj[k];
    }
  }

  return result;
}

export function getEnvNumeric(envName: string, defaultValue = 0) {
  if (process.env["envName"]) {
    return +process.env["envName"];
  }
  return defaultValue;
}

export function formatWebsite(website?: string) {
  if (!website) {
    return "";
  }

  if (website.match(/^https:/)) {
    return website;
  }
  return `https://${website}`;
}

export function toFixed(value: number | string | undefined, decimal = 1) {
  if (!value) {
    return 0;
  }
  if (typeof value === "string") {
    return parseFloat(value).toFixed(decimal);
  }
  return value.toFixed(decimal);
}

export const PUBLIC_ROUTES = [
  "/",
  "/login",
  "/about",
  "/products",
  "/register",
  "/register/verify",
  "/contact",
  "/forgot-password",
];

export function matchPublicRoutes(pathname: string) {
  return PUBLIC_ROUTES.includes(pathname);
}

export function displayPhoneNumber(
  phone?: string,
  countryCode?: string | null
) {
  if (phone && countryCode) {
    const countryCodeToTrim = getCountryPhoneCode(countryCode);
    return phone.replace(countryCodeToTrim, "");
  }
  return "";
}

export function isBlank(value: string) {
  return value.trim().length === 0;
}

export function formatBlankWithDefault(
  value: string,
  defaultValue: string = "-",
  shouldCapitalize = false
): string {
  if (isBlank(value)) {
    return defaultValue;
  }
  if (shouldCapitalize) {
    return capitalize(value);
  }
  return value;
}

export function isMoneyTransferComplete(status?: string) {
  if (status) {
    return [
      "PAYOUT_ITEM_SUCCESS",
      "PAYOUT_COMPLETE",
      "EXHUT_FORCE_COMPLETE",
      "COMPLETED",
    ].includes(status);
  }
  return false;
}

export function getCurrencySymbol(countryCode: string = "USA") {
  if (!countryCode) {
    return "$"; // always return $ in any cases
  }
  let symbol = "$";
  switch (countryCode) {
    case "IND":
      symbol = "₹";
      break;
    case "UK":
      symbol = "£";
      break;
    case "JPN":
      symbol = "¥";
      break;
  }
  return symbol;
}
