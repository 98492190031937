import { Divider, Paper } from "@mui/material";
import { DateField, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Alert, Avatar, Button, Flex, Input } from "antd";
import { useFormik } from "formik";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useDeleteMe } from "../../api/api.delete";
import {
  useGetProfile,
  useGetUserVerificationDetails,
} from "../../api/api.get";
import { useUpdateProfile } from "../../api/api.update";
import ConfirmationDialog from "../../components/dialog/confirmation.dialog.component";
import CountryDropdown from "../../components/dropdowns/country.dropdown.component";
import FormRow, { FormRowCol } from "../../components/forms/form.row.component";
import { useNotifications2 } from "../../components/notifications/notification";
import { useAuthContext } from "../../providers/auth.provider";
import { useLayoutContext } from "../../providers/layout.provider";
import {
  ROUTES,
  getCountryPhoneCode,
  getSubscription,
} from "../../utils/constants";
import { getISOCodes } from "../../utils/country";
import { capitalize } from "../../utils/functions";
import AddressSection from "./profile/address-section";
import PhoneSection from "./profile/phone-section";

export default function ProfilePage() {
  const { user: userAuth } = useAuthContext();
  const { data, refetch } = useGetProfile();
  const { isMobile } = useLayoutContext();
  const [deleteConf, showDeleteConf] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const { notification, notifySuccess, notifyError } = useNotifications2();

  useEffect(() => {
    if (userAuth) {
      refetch();
    }
  }, [userAuth]);

  const user = data;

  const navigate = useNavigate();

  const { mutateAsync: deleteUserAsync, isLoading: isDeleteLoading } =
    useDeleteMe();
  const { mutateAsync: updateProfileAsync, isLoading: isUpdateLoading } =
    useUpdateProfile();
  const { data: userVerificationDetails } = useGetUserVerificationDetails();
  const [verifiedProfile, setVerifiedProfile] = useState(false);

  useEffect(() => {
    if (userVerificationDetails) {
      setVerifiedProfile(
        userVerificationDetails.addressVerified &&
          userVerificationDetails.smsVerified
      );
    }
  }, [userVerificationDetails]);

  const formik = useFormik({
    initialValues: {
      firstName: user?.firstName ?? "",
      lastName: user?.lastName ?? "",
      countryCode: user?.countryCode ?? "USA",
      dob: user?.dob,
    },
    validationSchema: Yup.object({
      lastName: Yup.string().required("Please enter last name."),
      firstName: Yup.string().required("Please enter first name."),
    }),
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: async (values) => {
      // @ts-ignore
      delete values["consent"];

      updateProfileAsync({ ...values })
        .then(() => {
          notifySuccess({
            title: "Success",
            description: "Profile successfully saved.",
          });
        })
        .catch((err) => {
          if (err.response.status === 409) {
            notifyError(
              err.response.data?.message ?? "Whoops! Please try again"
            );
          } else {
            notifyError("Whoops! Something went wrong. Please try again.");
          }
        })
        .finally(() => setIsEditMode(false));
    },
  });
  const countryCode = useMemo(
    () => getCountryPhoneCode(formik.values.countryCode),
    [formik.values.countryCode]
  );

  const handleDeletion = () => {
    deleteUserAsync(undefined)
      .then(() => {
        navigate(ROUTES.ADMIN_USERS);
      })
      .catch(() => {
        notifyError("Whoops! Something went wrong. Please try again.");
      });
  };

  return (
    <div className="body" style={{ margin: "0% 10%" }}>
      <ConfirmationDialog
        open={deleteConf}
        title={"Delete Account?"}
        heading="Are you sure?"
        subtitle="This will permanently delete all your data from all ExpenseHut products. Do you wish to continue?"
        onClose={() => showDeleteConf(false)}
        onProceed={handleDeletion}
      />
      {notification}

      <div>
        <div className="mb-40"></div>

        <div className="divCenterAlign" style={{}}>
          <div
            style={{
              width: "100%",
              padding: "60px 40px 40px 40px",
              flexDirection: isMobile ? "column" : "row",
              justifyContent: "space-between",
              alignItems: "center",
              display: "flex",
              borderRadius: 0,
              backgroundColor: "white",
              boxShadow: "3px 1px 5px 5px #e6ddd6",
            }}
          >
            <div className="divCenterAlign divColumn">
              <Avatar src={<img src="/assets/icons/man.svg" />} size={80} />

              <div className="my-20">
                <strong style={{ fontSize: 20 }}>{`${capitalize(
                  formik.values.lastName
                )}, 
                ${capitalize(formik.values.firstName)} `}</strong>
              </div>

              <div className="my-20">
                <Alert
                  type={verifiedProfile ? "success" : "warning"}
                  message={
                    verifiedProfile ? "Profile verified" : "Profile unverified"
                  }
                />
              </div>
            </div>

            <div className="">
              <div
                id="subscription-banner"
                className="divCenterAlign "
                style={{
                  backgroundColor: "orange",
                  color: "white",
                  borderRadius: 20,
                  padding: 20,
                  fontWeight: "bold",
                }}
              >
                {getSubscription(user?.entitlement)}
              </div>

              <div className="mt-20">
                <ReactCountryFlag
                  className="emojiFlag"
                  countryCode={getISOCodes(user?.countryCode)}
                  style={{
                    fontSize: 40,
                  }}
                  aria-label="United States"
                />
              </div>
            </div>
          </div>
        </div>

        <Paper
          elevation={6}
          sx={{
            minWidth: "40%",
            paddingLeft: 3,
            paddingRight: 3,
            marginTop: 5,
            boxShadow: "3px 1px 5px 5px #e6ddd6",
          }}
        >
          <div className="p-10">
            <h3 style={{ color: "darkgrey" }}> Personal Information</h3>
          </div>
          {!isEditMode && (
            <div className="divRight mx-20">
              <button
                className="buttonAsLink"
                style={{
                  fontWeight: "bold",
                  color: "var(--exhut-total-green)",
                  fontSize: 15,
                }}
                onClick={(e) => {
                  e.preventDefault();
                  setIsEditMode(!isEditMode);
                }}
                id="edit-profile-btn"
              >
                Edit
              </button>
            </div>
          )}
          <form style={{ padding: 30 }}>
            <div className="divColumn mt-0">
              <FormRowCol label="First Name">
                {!isEditMode && (
                  <strong>{capitalize(formik.values.firstName)}</strong>
                )}
                {isEditMode && (
                  <Input
                    {...formik.getFieldProps("firstName")}
                    size="large"
                    status={!!formik.errors.firstName ? "error" : undefined}
                  />
                )}
              </FormRowCol>
              <FormRowCol label="Last Name">
                {!isEditMode && (
                  <strong>{capitalize(formik.values.lastName)}</strong>
                )}
                {isEditMode && (
                  <Input
                    {...formik.getFieldProps("lastName")}
                    size="large"
                    status={!!formik.errors.lastName ? "error" : undefined}
                  />
                )}
              </FormRowCol>

              <FormRowCol label="Email">
                <strong>{user?.email} </strong>
              </FormRowCol>

              <FormRowCol label="Country">
                {!isEditMode && <strong>{formik.values.countryCode}</strong>}
                {isEditMode && (
                  <CountryDropdown
                    onChange={(value) =>
                      formik.setFieldValue("countryCode", value)
                    }
                    value={formik.values.countryCode}
                  />
                )}
              </FormRowCol>

              <FormRowCol label="Date Of Birth">
                {!isEditMode && (
                  <strong>
                    {formik.values.dob
                      ? moment(formik.values.dob).format("DD MMM YYYY")
                      : "-"}
                  </strong>
                )}
                {isEditMode && (
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DateField
                      slotProps={{
                        textField: { id: "dob", style: { borderWidth: 0 } },
                      }}
                      sx={{ width: 290, border: "0px" }}
                      value={moment(formik.values.dob)}
                      onChange={(newValue: any) =>
                        formik.setFieldValue(
                          "dob",
                          moment(newValue).format("YYYY-MM-DD")
                        )
                      }
                    />
                  </LocalizationProvider>
                )}
              </FormRowCol>

              <Divider />
            </div>

            <div className="divSpread">
              <FormRow label="">
                {isEditMode && (
                  <Flex gap={30}>
                    <Button
                      type="primary"
                      loading={isUpdateLoading}
                      onClick={formik.submitForm}
                      disabled={Object.keys(formik.errors).length > 0}
                      id="update-profile"
                    >
                      {" "}
                      Update
                    </Button>

                    <Button onClick={() => setIsEditMode(false)}>Cancel</Button>
                  </Flex>
                )}
              </FormRow>
            </div>
          </form>
        </Paper>

        <PhoneSection areaCode={countryCode} />

        <AddressSection />

        <div className="">
          <hr className="my-80" />

          <div className="my-20 pb-80">
            <div
              style={{
                border: "1px solid #ffb2b2",
                background: "",
                borderRadius: 10,
                color: "",
              }}
              className="p-16"
            >
              <p>Danger Zone</p>
              <div className="my-20">
                <Button
                  id="delete-account"
                  type="primary"
                  danger
                  loading={isDeleteLoading}
                  onClick={() => showDeleteConf(true)}
                >
                  {" "}
                  Delete Account
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
