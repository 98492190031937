import { Alert, Button, Flex, Input, Modal, Select } from "antd";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { useDeleteSubscriptions } from "../../../api/api.delete";
import ErrorPanel from "../../../components/error.panel.component";
import { useCurrency } from "../../../hooks/useCurrency";
import { useState } from "react";

export default function CancelSubscriptionModal({
  open,
  onSuccess,
  onCancel,
}: {
  onSuccess: () => void;
  open: boolean;
  onCancel: () => void;
}) {
  const { mutateAsync: deleteAsync, error } = useDeleteSubscriptions();
  const [reason, setReason] = useState();
  const handleCancelSubscription = () => {
    deleteAsync({}).then(onSuccess);
  };

  return (
    <Modal title="Cancel?" open={open} onCancel={onCancel} footer={null}>
      <Flex gap={30} vertical className="divCenterAlign">
        <div>
          {!!error && (
            <div className="p-40">
              <Alert type="error" message="Whoops! Something went wrong." />
            </div>
          )}
          <h3>Sorry about that! .</h3>

          <h4>We are always listening and looking for ways to improve.</h4>

          <img src="/assets/cancel-subscription.svg" width={"30%"} />

          <Flex vertical gap={10} className="my-40">
            <span>Please select a reason (optional)</span>

            <Select
              showSearch
              style={{ minWidth: 200 }}
              placeholder="Select a reason"
              optionFilterProp="label"
              value={reason}
              onChange={(value) => setReason(value)}
              options={[
                { label: "Very Expensive", value: "EXPENSIVE" },
                {
                  label: "Better Price Available From Others",
                  value: "BETTER_PRICE",
                },
                { label: "My Business Is Closing", value: "BUSINESS_CLOSED" },
                {
                  label: "I Need A More Flexible Plan",
                  value: "FLEXIBLE_PLAN_NEEDED",
                },
              ]}
            />
          </Flex>
        </div>

        <div>
          <span className="mediumText">
            Please visit our{" "}
            <a
              href="https://help.expensehut.com/refunds"
              target="_blank"
              rel="noreferrer"
            >
              Cancellation and Refund policy
            </a>{" "}
            for more questions.
          </span>
        </div>

        <div className="mb-40">
          <Button danger type="primary" onClick={handleCancelSubscription}>
            {" "}
            Yes, Cancel My Subscription
          </Button>
        </div>
      </Flex>
    </Modal>
  );
}
