import { Button, Checkbox, Divider, Flex, Skeleton, Spin } from "antd";

import { LoadingOutlined } from "@ant-design/icons";
import { Content } from "antd/es/layout/layout";
import { useEffect, useMemo, useState } from "react";
import Confetti from "react-confetti";
import { useLocation, useSearchParams } from "react-router-dom";
import { useCreateSubscription } from "../../../api/api";
import { useGetSession, useGetSubscriptions } from "../../../api/api.get";
import { useNotifications2 } from "../../../components/notifications/notification";
import { getCurrencySymbol } from "../../../utils/functions";
import { ProfitCalculatorModal, RewardsCalculatorModal } from "./calc-modal";
import CancelSubscriptionModal from "./cancel-subscription";
import { ComparisonIndia, ComparisonUSA } from "./comparison";
import SubscriptionsReadyPage from "./subscriptions-ready.page";

enum SUBSCRIPTION {
  STARTER = 8,
  ENTERPRISE = 10,
}

export default function SubscriptionsPage() {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const subscriptionRedirected = queryParams.get("subscription_id");
  const [confirmCancel, setConfirmCancel] = useState<boolean>(false);

  const { data: subscriptionsData, isLoading, refetch } = useGetSubscriptions();

  const { data: session, refetch: refetchSession } = useGetSession();
  const [showCofetti, setShowConfetti] = useState<boolean>(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const [acceptTerms, setAcceptTerms] = useState<boolean>(false);
  const [subscriptionCheckInProgress, setSubscriptionCheckInProgress] =
    useState<boolean>(false);
  const { mutateAsync, isLoading: isCreatePending } = useCreateSubscription();
  const [selectedPackage, setSelectedPackage] =
    useState<ISubscriptionPackage>();

  // we have entitlements from 0, 1, 2, 3 in ExpenseHut.
  // 4 will be business starter, 5 will be business enterprise

  useEffect(() => {
    if (!!subscriptionRedirected) {
      setSubscriptionCheckInProgress(true);

      setTimeout(() => {
        setSubscriptionCheckInProgress(false);
        setShowConfetti(true);
        refetch();
        refetchSession();
      }, 15000);
    }
  }, [subscriptionRedirected]);

  const { notifySuccess, notification, notifyError } = useNotifications2();

  // can upgrade only when previous subscript is not there or previous subscription is active one.
  // It should not be in PENDING state.
  const isSubscriptionInProgress = subscriptionsData?.status === "PENDING";

  const upgradablePackages = useMemo(() => {
    const currEntitlement = session?.entitlement ?? 0;
    return getPackages(session?.country_code).filter(
      (subPackage: ISubscriptionPackage) => subPackage.value > currEntitlement
    );
  }, [session?.entitlement, session?.country_code]);

  if (selectedPackage) {
    return (
      <SubscriptionsReadyPage
        selectedPackage={selectedPackage}
        onBack={() => setSelectedPackage(undefined)}
      />
    );
  }

  return (
    <div
      id="subscriptions-container"
      className="p-40 flex-1"
      style={{ minHeight: 600 }}
    >
      {notification}

      {showCofetti && (
        <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
          recycle={false}
          onConfettiComplete={() => {
            setShowConfetti(false);
            searchParams.delete("subscription_id");
            setSearchParams(searchParams);
          }}
        />
      )}

      <CancelSubscriptionModal
        open={confirmCancel}
        onSuccess={() => {
          setConfirmCancel(false);
          refetch();
          refetchSession();
        }}
        onCancel={() => setConfirmCancel(false)}
      />

      <div className={`divCenterAlign divColumn p-32`}>
        <Content
          style={{
            boxShadow: "3px 1px 5px 5px #e6ddd6",
            minWidth: "40%",
            padding: 40,
            backgroundColor: "darkslateblue",
            color: "white",
          }}
        >
          {subscriptionCheckInProgress && (
            <Flex vertical gap={20}>
              <h4>Almost there!! </h4>
              <h4>Just a few more seconds..</h4>
              <Spin
                indicator={<LoadingOutlined spin style={{ fontSize: 60 }} />}
              />
            </Flex>
          )}

          {!subscriptionCheckInProgress && (
            <Skeleton loading={isLoading}>
              {isSubscriptionInProgress && (
                <Flex vertical gap={20}>
                  <h4>
                    Please wait while we are working on the subscription
                    upgrade..
                  </h4>
                  <img src="/assets/subscription-pending.png" width={300} />

                  <span>
                    Taking longer?{" "}
                    <a
                      href="https://help.expensehut.com/contact"
                      target="_blank"
                    >
                      Contact Us
                    </a>
                  </span>
                </Flex>
              )}

              {!isSubscriptionInProgress && (
                <div>
                  <CurrentSubscriptionBanner
                    entitlement={session?.entitlement}
                  />

                  {!subscriptionsData && (
                    <Flex gap={10} vertical className="mt-40">
                      <h2 style={{ color: "lime" }}>Looking to upgrade? </h2>
                    </Flex>
                  )}

                  <div className="my-40">
                    <BusinessComparisonTable
                      countryCode={session?.country_code}
                    />

                    <div className="my-80">
                      <img
                        src="/assets/upgrade.svg"
                        alt="upgrade"
                        width={"50%"}
                      />
                    </div>

                    <Divider style={{ backgroundColor: "grey" }} />

                    {upgradablePackages.map((subPkg) => {
                      return (
                        <SubscriptTierItemsCard
                          subPackage={subPkg}
                          onUpgrade={() => setSelectedPackage(subPkg)}
                        />
                      );
                    })}
                  </div>

                  {subscriptionsData?.status === "ACTIVE" && (
                    <div className="my-20">
                      <Divider />

                      <div className="my-60">
                        <Button
                          onClick={() => setConfirmCancel(true)}
                          danger
                          type="primary"
                        >
                          Cancel Subscription{" "}
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </Skeleton>
          )}
        </Content>
      </div>
    </div>
  );
}

export function SubscriptTierItemsCard({
  subPackage,
  onUpgrade,
}: {
  subPackage: ISubscriptionPackage;
  onUpgrade?: () => void;
}) {
  return (
    <div className="divCenterAlign divColumn">
      <h2>{subPackage.label}</h2>
      <Flex gap={0} vertical>
        <h1 style={{ fontSize: "xxx-large" }}>{subPackage?.price}</h1>
        {!subPackage.isContactUs && (
          <span className="mediumText" style={{ color: "yellow" }}>
            per month
          </span>
        )}
      </Flex>
      <div className="p-20">
        <table
          style={{
            textAlign: "left",

            border: "1px solid lightgrey",
            borderRadius: 10,
          }}
          cellPadding={"20px"}
        >
          <tbody>
            {subPackage.features?.map((item: ITierItem, index: number) => (
              <tr key={index}>
                <td style={{ textAlign: "left" }}>{item.title}</td>
                <td style={{ textAlign: "right" }}> {item.value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {!subPackage.isContactUs && onUpgrade && (
        <div className="my-20">
          <Button onClick={onUpgrade} type="primary">
            Upgrade
          </Button>
        </div>
      )}

      <Divider style={{ backgroundColor: "grey" }} />
    </div>
  );
}

interface ITierItem {
  title: string;
  value: any;
}

function CurrentSubscriptionBanner({ entitlement }: { entitlement?: number }) {
  const header = useMemo(
    () => getPackages(null!!).find((p) => p.value === entitlement)?.label,
    [entitlement]
  );

  const bgColor = useMemo(() => {
    if (entitlement === SUBSCRIPTION.STARTER) {
      return "var(--exhut-blue)";
    } else if (entitlement === SUBSCRIPTION.ENTERPRISE) {
      return "midnightblue";
    }
    return "var(--exhut-orange)";
  }, [entitlement]);

  return (
    <Flex gap={20} vertical className="divCenterAlign">
      <span className="">Your current subscription is</span>{" "}
      <div
        style={{
          backgroundColor: bgColor,
          border: "1px solid " + bgColor,
          borderRadius: 10,
          color: "white",
          padding: 10,
        }}
      >
        <strong>{header}</strong>
      </div>
    </Flex>
  );
}

export function getPackages(countryCode?: string) {
  const currency = getCurrencySymbol(countryCode);
  if (countryCode === "IND") {
    return [
      { value: 0, label: "Trial", features: [] },
      // { value: 1, label: 'Personal - Basic ($4.99)', price: "4.99"},
      // { value: 4, label: 'Personal - Premium ($19.99)', price: "9.99" },
      // { value: 5, label: 'Jobs - $9.99'},
      {
        value: 8,
        label: `Business - Starter (${currency}4999)`,
        price: currency + "4999",
        features: starterTierItems,
      },
      {
        value: 10,
        label: "Business - Enterprise (Contact Us)",
        price: "Contact Us",
        features: enterpriseTierItems,
        isContactUs: true,
        bgColor: "midnightblue",
      },
    ];
  }
  return [
    { value: 0, label: "Trial", features: [] },
    // { value: 1, label: 'Personal - Basic ($4.99)', price: "4.99"},
    // { value: 4, label: 'Personal - Premium ($19.99)', price: "9.99" },
    // { value: 5, label: 'Jobs - 499.99'},
    {
      value: 8,
      label: `Business - Starter`,
      price: currency + "99",
      features: starterTierItems,
    },
    {
      value: 10,
      label: "Business - Enterprise",
      price: "Contact Us",
      features: enterpriseTierItems,
      isContactUs: true,
      bgColor: "midnightblue",
    },
  ];
}

export interface ISubscriptionPackage {
  value: number;
  label: string;
  price?: string;
  features: ITierItem[];
  isContactUs?: boolean;
}

const starterTierItems: ITierItem[] = [
  { title: "Organizations", value: "1" },
  {
    title: "Use your own payment terminal",
    value: (
      <img
        alt="allowed"
        src={"/assets/icons/checked.png"}
        style={{ width: 20 }}
      />
    ),
  },
  {
    title: "Use Integrated Payment Terminal",
    value: (
      <img
        alt="unavailable"
        src={"/assets/icons/cancel.png"}
        style={{ width: 20 }}
      />
    ),
  },
  { title: "Rewards", value: "5k" },
  {
    title: "All POS types",
    value: (
      <img
        alt="allowed"
        src={"/assets/icons/checked.png"}
        style={{ width: 20 }}
      />
    ),
  },
  { title: "Orders limit", value: "250" },
  { title: "Invoice", value: "100" },
  { title: "Buzz", value: "250" },
];

const enterpriseTierItems: ITierItem[] = [
  { title: "Organizations", value: "10" },
  {
    title: "Use your own payment terminal",
    value: (
      <img
        alt="allowed"
        src={"/assets/icons/checked.png"}
        style={{ width: 20 }}
      />
    ),
  },
  {
    title: "Use Integrated Payment Terminal",
    value: (
      <img
        alt="allowed"
        src={"/assets/icons/checked.png"}
        style={{ width: 20 }}
      />
    ),
  },
  { title: "Rewards", value: "Unlimited" },
  {
    title: "All POS types",
    value: (
      <img
        alt="allowed"
        src={"/assets/icons/checked.png"}
        style={{ width: 20 }}
      />
    ),
  },
  { title: "Orders limit", value: "Unlimited" },
  { title: "Invoice", value: "Unlimited" },
  { title: "Buzz", value: "Unlimited" },
];

function BusinessComparisonTable({ countryCode }: { countryCode?: string }) {
  const [openProfitCalculator, setopenProfitCalculator] =
    useState<boolean>(false);
  const [openRewardsCalculator, setopenRewardsCalculator] =
    useState<boolean>(false);

  if (countryCode) {
    return (
      <div className="my-80">
        <ProfitCalculatorModal
          open={openProfitCalculator}
          onClose={() => setopenProfitCalculator(false)}
        />

        <RewardsCalculatorModal
          open={openRewardsCalculator}
          onClose={() => setopenRewardsCalculator(false)}
        />

        <div>
          <h3>Comparison</h3>
        </div>

        {countryCode === "IND" && <ComparisonIndia />}

        {countryCode === "USA" && <ComparisonUSA />}

        <div className="my-40 divSpread">
          <Button
            onClick={() => setopenProfitCalculator(true)}
            type="link"
            style={{ color: "yellow" }}
          >
            Profit Calculator with BUZZ
          </Button>

          <Button
            onClick={() => setopenRewardsCalculator(true)}
            type="link"
            style={{ color: "yellow" }}
          >
            Rewards Calculator
          </Button>
        </div>
      </div>
    );
  }

  return null;
}
