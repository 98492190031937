import LoadingButton from "@mui/lab/LoadingButton";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";

import { Button, Paper } from "@mui/material";
import { useGetProfile } from "../../../api/api.get";
import { useUpdatePhone } from "../../../api/api.update";
import OKDialogComponent from "../../../components/dialog/ok.dialog.component";
import { FormRowCol } from "../../../components/forms/form.row.component";
import { useAuthContext } from "../../../providers/auth.provider";
import { useLayoutContext } from "../../../providers/layout.provider";
import { IStatusMsg } from "../../../types/types";
import {
  displayPhoneNumber,
  formatBlankWithDefault,
} from "../../../utils/functions";
import { Input } from "antd";
import { useNotifications2 } from "../../../components/notifications/notification";

export default function PhoneSection({ areaCode }: { areaCode: string }) {
  const { user: userAuth } = useAuthContext();
  const { data, refetch } = useGetProfile();
  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    if (userAuth) {
      refetch();
    }
  }, [userAuth]);

  const user = data;

  const [isLoading, setIsLoading] = useState(false);
  const { isMobile } = useLayoutContext();
  const { notification, notifySuccess, notifyError } = useNotifications2();

  const { mutateAsync: updatePhoneAsync } = useUpdatePhone();

  const formik = useFormik({
    initialValues: {
      phone: displayPhoneNumber(user?.phone, user?.countryCode),
      consent: false,
    },
    validationSchema: Yup.object({
      phone: Yup.string()
        .matches(/^[0-9]+$/, "Please enter only numbers.")
        .required("Please enter phone."),
      consent: Yup.bool().oneOf([true], "Please accept the consent."),
    }),
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: async (values) => {
      // @ts-ignore
      delete values["consent"];
      setIsLoading(true);

      updatePhoneAsync({ ...values, phone: `${areaCode}${values.phone}` })
        .then(() => {
          notifySuccess({
            title: "Success",
            description: "Phone number successfully updated.",
          });
          setIsEditMode(false);
        })
        .catch((err) => {
          if (err.response.status === 409) {
            notifyError(
              err.response.data?.message ?? "Whoops! Please try again"
            );
          } else {
            notifyError("Whoops! Please try again");
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
  });

  return (
    <div className="">
      {notification}

      <Paper
        elevation={6}
        sx={{
          minWidth: "40%",
          paddingLeft: 3,
          paddingRight: 3,
          marginTop: 5,
          boxShadow: "3px 1px 5px 5px #e6ddd6",
        }}
      >
        <div className="p-10">
          <h3 style={{ color: "darkgrey" }}>Communications</h3>
        </div>

        {!isEditMode && (
          <div className="divRight mx-20">
            <button
              className="buttonAsLink"
              style={{
                fontWeight: "bold",
                color: "var(--exhut-total-green)",
                fontSize: 15,
              }}
              onClick={(e) => {
                e.preventDefault();
                setIsEditMode(!isEditMode);
              }}
              id="edit-phone-btn"
            >
              Edit
            </button>
          </div>
        )}

        <form style={{ paddingLeft: 30, paddingRight: 30, paddingBottom: 30 }}>
          <div className={isMobile ? "" : "divSpread"}>
            <FormRowCol label="Phone">
              <div
                className={isMobile ? "divColumn" : "divFlex divAlignItemsOnly"}
              >
                <div className={isMobile ? "my-10" : undefined}>
                  {isEditMode && (
                    <Input
                      type="text"
                      prefix={areaCode}
                      className="bordered"
                      maxLength={10}
                      {...formik.getFieldProps("phone")}
                    />
                  )}
                  {!isEditMode && (
                    <strong>
                      {formatBlankWithDefault(formik.values.phone, "")}
                    </strong>
                  )}
                </div>
              </div>
              {formik.errors.phone && (
                <span style={{ fontSize: 15 }} className="errorMsg">
                  {formik.errors.phone}
                </span>
              )}

              {isEditMode && (
                <div className="divFlex my-20">
                  <p
                    style={{
                      fontSize: 15,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    className="mx-0-"
                  >
                    <input
                      id="consent-check"
                      type="checkbox"
                      checked={formik.values.consent}
                      style={{ marginRight: 10 }}
                      onChange={(e) =>
                        formik.setFieldValue("consent", e.target.checked)
                      }
                    />
                    By checking this box, you agreed to receive text messages
                    from the ExpenseHut platform and you are solely responsible
                    for any mobile carrier charges.
                  </p>
                </div>
              )}

              {formik.errors.consent && (
                <span style={{ fontSize: 15 }} className="errorMsg">
                  {formik.errors.consent}
                </span>
              )}
            </FormRowCol>
          </div>

          <div className="divSpread">
            {isEditMode && (
              <div className="divSpread">
                <LoadingButton
                  variant="contained"
                  color="info"
                  loading={isLoading}
                  onClick={formik.submitForm}
                  disabled={Object.keys(formik.errors).length > 0}
                  id="update-phone"
                >
                  {" "}
                  Update
                </LoadingButton>

                <Button onClick={() => setIsEditMode(false)}>Cancel</Button>
              </div>
            )}
          </div>
        </form>
      </Paper>
    </div>
  );
}
