import Icon, { MenuOutlined, PoweroffOutlined } from "@ant-design/icons";
import { Flex, Menu, Row } from "antd";
import capitalize from "capitalize";
import { Link } from "react-router-dom";
import { useGetProfile } from "../api/api.get";
import { ExhutCard } from "../components/card.component";
import FeedbackComponent from "../components/other/feedback.component";
import RewardsComponent from "../components/rewards.component";
import SubscriptionsPage from "./settings/subscriptions/subscriptions.page";

export default function HomePage() {
  const { data: profile } = useGetProfile();

  return (
    <div className={`mb-160`} id="home-body">
      <RewardsComponent />

      <div className="divSpread divAlignItemsOnly p-20">
        <Menu
          id="options-menu"
          style={{
            width: 60,
            backgroundColor: "white",
            border: "1px solid white",
          }}
          mode="horizontal"
          items={[
            {
              key: "0",
              icon: (
                <div className="divRight w-100">
                  <MenuOutlined style={{ cursor: "pointer" }} />
                </div>
              ),
              children: items,
            },
          ]}
        />
        <div>
          <Flex gap={5}>
            Welcome{" "}
            <Link to="/settings/profile">
              {" "}
              {capitalize(profile?.firstName ?? "Guest")}
            </Link>
          </Flex>
        </div>
      </div>

      <SubscriptionsPage />
    </div>
  );
}

const items = [
  {
    label: "Profile",
    url: "/settings/profile",
    icon: (
      <Link to="/settings/profile">
        {" "}
        <img src="/assets/icons/man.svg" width={20} />
      </Link>
    ),
    key: "11",
  },
  {
    label: "Settings",
    icon: (
      <Link to="/settings">
        {" "}
        <img src="/assets/icons/settings.svg" width={20} />
      </Link>
    ),
    key: "12",
  },
  {
    label: "SignOut",
    url: "https://wallet.expensehut.com",
    icon: (
      <Link to="/login?logout=1">
        <PoweroffOutlined />
      </Link>
    ),
    key: "13",
  },
];
