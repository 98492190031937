import { useFormik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import {
  useForgotPasswordMutation,
  useForgotPasswordValidateMutation,
} from "../api/api";
import ErrorPanel from "../components/error.panel.component";
import LinkComponent from "../components/link.component";
import StatusMessageComponent from "../components/status-message.component";
import { useLayoutContext } from "../providers/layout.provider";
import { IStatusMsg } from "../types/types";
import { ROUTES } from "../utils/constants";
import ReCAPTCHA from "react-google-recaptcha";
import { getRecaptchaKey, isRecaptchaEnabled } from "../utils/app";
import { Alert, Button, Input } from "antd";

export default function ForgotPassword() {
  const { isMobile } = useLayoutContext();
  const { mutateAsync, isLoading, isError } = useForgotPasswordMutation();
  const { mutateAsync: validateForgotPasswordAsync } =
    useForgotPasswordValidateMutation();

  const [statusMsg, setStatusMsg] = useState<IStatusMsg | undefined>();
  const [email, setEmail] = useState<string>("");
  const [stepIndex, setStepIndex] = useState<number>(0);
  const [passcode, setPasscode] = useState<string>("");
  const [success, setSuccess] = useState<boolean>();
  const [recaptchaVerified, setRecaptchaVerified] = useState(
    !isRecaptchaEnabled
  );

  const formik = useFormik({
    initialValues: {
      passcode: "",
      email: "",
      newPassword: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please enter email."),
      passcode: Yup.string().when("email", {
        is: () => email.length > 0,
        then: (schema) =>
          schema
            .matches(/^[0-9]+$/, "Passcode can only be numbers.")
            .required("Please enter passcode."),
      }),
      newPassword: Yup.string().when("email", {
        is: () => email.length > 0,
        then: (schema) => schema.required("Please new password"),
      }),
    }),
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: async (values) => {
      if (stepIndex === 0) {
        mutateAsync({ email: values.email })
          .then((resp) => {
            setEmail(values.email);
            setStepIndex(1);
            setStatusMsg({
              type: "success",
              text: "We have sent a verification code to the registered email of this account.",
            });
          })
          .catch((e) => {
            if (e.response?.status === 403) {
              setStatusMsg({
                type: "error",
                text: "You have exceeded the number of attempts. Please contact the admin.",
              });
            } else {
              setStatusMsg({
                type: "error",
                text: "This email does not exist in the system. Please try again.",
              });
            }
          });
      } else if (stepIndex === 2) {
        validateForgotPasswordAsync({
          email: values.email,
          passcode: +values.passcode,
          newPassword: values.newPassword,
        })
          .then((resp) => {
            formik.resetForm();
            setEmail("");
            setStepIndex(0);
            setSuccess(true);
          })
          .catch((e) => {
            if (e.response?.status === 401) {
              setStatusMsg({
                type: "error",
                text: "The passcode is incorrect. Please try again.",
              });
            } else {
              setStatusMsg({
                type: "error",
                text: "Whoops! Something went wrong. Please try again.",
              });
            }
          });
      }
    },
  });

  useEffect(() => {
    setStatusMsg(undefined);
  }, [formik.values]);

  const setLoginReady = (e: any) => {
    setRecaptchaVerified(true);
  };

  return (
    <div className="divCenterAlign" style={{ width: "100%" }}>
      <div className={`${isMobile ? "mx-20" : "mx-80"}`} style={{}}>
        <Header isMobile={isMobile} />

        <StatusMessageComponent
          message={statusMsg}
          onClose={() => setStatusMsg(undefined)}
        />

        {success && (
          <div className="divCeterAlign">
            <Alert type="success" message="Password successfully changed!" />
          </div>
        )}

        {!success && (
          <form
            onSubmit={formik.handleSubmit}
            className="divColumn divCenterAlign px-20 pb-40"
            style={{}}
          >
            <>
              <div style={{ height: 100 }} className="divColumn mb-0">
                <Input
                  {...formik.getFieldProps("email")}
                  size="large"
                  id="email"
                  disabled={stepIndex > 0}
                  placeholder="Enter your email address..."
                  htmlSize={40}
                  status={!!formik.errors.email ? "error" : undefined}
                />
              </div>

              {isRecaptchaEnabled && (
                <ReCAPTCHA
                  sitekey={getRecaptchaKey()}
                  onChange={setLoginReady}
                />
              )}

              {stepIndex === 0 && (
                <div className="mx-20 my-10">
                  <Button
                    type="primary"
                    disabled={isLoading || !recaptchaVerified}
                    onClick={formik.submitForm}
                  >
                    Continue
                  </Button>
                </div>
              )}
            </>

            {stepIndex > 0 && (
              <>
                <div style={{ height: 100 }} className="divColumn mb-0">
                  <Input
                    placeholder="Passcode..."
                    autoFocus={true}
                    className="bordered"
                    {...formik.getFieldProps("passcode")}
                    size="large"
                  />
                  <div style={styles.formRowMin}>
                    <ErrorPanel message={formik.errors.passcode} />
                  </div>
                </div>

                {stepIndex === 1 && (
                  <div style={{ height: 70 }} className="mx-20">
                    <Button
                      type="primary"
                      onClick={(e) => {
                        setPasscode(formik.values.passcode);
                        setStepIndex(2);
                      }}
                    >
                      Continue
                    </Button>
                  </div>
                )}
              </>
            )}

            {stepIndex > 1 && (
              <>
                <div style={{ height: 100 }} className="divColumn mb-0">
                  <Input.Password
                    {...formik.getFieldProps("newPassword")}
                    id="newPassword"
                    placeholder="Enter New Password..."
                    htmlSize={40}
                    size="large"
                  />
                  <div style={styles.formRowMin}>
                    <ErrorPanel message={formik.errors.newPassword} />
                  </div>
                </div>

                <div style={{ height: 70 }} className="mx-20">
                  <Button
                    type="primary"
                    onClick={(e) => {
                      setPasscode(formik.values.passcode);
                      setStepIndex(2);
                      formik.submitForm();
                    }}
                  >
                    Submit{" "}
                  </Button>
                </div>
              </>
            )}
            <div className="my-20">
              <LinkComponent title="Login" path={ROUTES.BASE} />
            </div>
          </form>
        )}

        <hr className="my-40" />

        <div className="divColumn divCenterAlign">
          <div className="mb-20">
            <h2>Did you know?</h2>
          </div>

          <div>All ExpenseHut apps can be used with same credentials.</div>
        </div>
        <hr className="my-40" />
      </div>
    </div>
  );
}

const Header = ({ isMobile }: { isMobile?: boolean }) => {
  return (
    <div
      className={`${
        isMobile ? "divColumn divCenterAlign" : "divFlex"
      } mt-40 mb-40`}
    >
      <div style={{ width: "20%" }}></div>
      <div className="divCenterAlign mb-20" style={{ width: "100%" }}>
        <h1>Forgot Password?</h1>
      </div>
    </div>
  );
};

const styles = {
  formRowMin: {
    display: "flex",
    margin: "5px 10px",
  },
  formRow: {
    marginTop: 30,
    display: "flex",
  },
  formColumn: {
    marginTop: 30,
    display: "flex",
    flexDirection: "column",
  },
};
