import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import { IStatusMsg } from "../types/types";
import React from "react";
import { Alert } from "antd";

interface IProps {
  message?: IStatusMsg;
  onClose: () => void;
}

/**
 * You must && for value.
 * @param message
 * @param onClose
 * @returns
 */
export default function StatusMessageComponent({ message, onClose }: IProps) {
  if (!message) {
    return null;
  }

  return (
    <div className="my-20" style={{ width: "auto" }}>
      <Alert closable type={message.type} message={message.text} />
    </div>
  );
}
