import { useFormik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";

import { Paper } from "@mui/material";
import { Button, Flex, Input } from "antd";
import { useGetProfile } from "../../../api/api.get";
import { useUpdateProfile } from "../../../api/api.update";
import OKDialogComponent from "../../../components/dialog/ok.dialog.component";
import { FormRowCol } from "../../../components/forms/form.row.component";
import { useAuthContext } from "../../../providers/auth.provider";
import { useLayoutContext } from "../../../providers/layout.provider";
import { IStatusMsg } from "../../../types/types";
import { formatBlankWithDefault } from "../../../utils/functions";
import { useNotifications2 } from "../../../components/notifications/notification";

export default function AddressSection({ areaCode }: { areaCode?: string }) {
  const { user: userAuth } = useAuthContext();
  const { data, refetch } = useGetProfile();
  const [statusMsg, setStatusMsg] = useState<IStatusMsg>();
  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    if (userAuth) {
      refetch();
    }
  }, [userAuth]);

  const user = data;

  const [isLoading, setIsLoading] = useState(false);
  const { isMobile } = useLayoutContext();

  const { mutateAsync: updateAddressAsync } = useUpdateProfile();
  const { notification, notifySuccess, notifyError } = useNotifications2();

  const formik = useFormik({
    initialValues: {
      street1: user?.street1 ?? "",
      street2: user?.street2 ?? "",
      city: user?.city ?? "",
      state: user?.state ?? "",
      zipCode: user?.zipCode ?? "",
    },
    validationSchema: Yup.object({
      zipCode: Yup.string()
        .matches(/^[0-9]+$/, "Please enter only numbers.")
        .required("Please enter zipcode."),
      state: Yup.string().required("Please enter state."),
      street1: Yup.string().required("Please enter street address"),
      city: Yup.string().required("Please enter city"),
    }),
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: async (values) => {
      setIsLoading(true);

      updateAddressAsync(values)
        .then(() => {
          // notifySuccess({ title: 'Success', description: 'Address successfully updated.'})
          setIsEditMode(false);
        })
        .catch((err) => {
          if (err.response.status === 409) {
            notifyError(
              err.response.data?.message ?? "Whoops! Please try again"
            );
          } else {
            notifyError("Whoops! Something went wrong. Please try again.");
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
  });

  return (
    <div className="">
      {notification}
      <Paper
        elevation={6}
        sx={{
          minWidth: "40%",
          paddingLeft: 3,
          paddingRight: 3,
          marginTop: 5,
          boxShadow: "3px 1px 5px 5px #e6ddd6",
        }}
      >
        <div className="p-10">
          <h3 style={{ color: "darkgrey" }}> Address</h3>
        </div>

        {!isEditMode && (
          <div className="divRight mx-20">
            <button
              className="buttonAsLink"
              style={{
                fontWeight: "bold",
                color: "var(--exhut-total-green)",
                fontSize: 15,
              }}
              onClick={(e) => {
                e.preventDefault();
                setIsEditMode(!isEditMode);
              }}
              id="edit-address-btn"
            >
              Edit
            </button>
          </div>
        )}

        <form style={{ paddingLeft: 30, paddingRight: 30, paddingBottom: 30 }}>
          <FormRowCol label="Street 1">
            <div
              className={isMobile ? "divColumn" : "divFlex divAlignItemsOnly"}
            >
              <div className={isMobile ? "my-10" : undefined}>
                {isEditMode && (
                  <Input
                    status={formik.errors.street1 ? "error" : undefined}
                    {...formik.getFieldProps("street1")}
                  />
                )}
                {!isEditMode && (
                  <strong>
                    {formatBlankWithDefault(formik.values.street1, "", true)}
                  </strong>
                )}
              </div>
            </div>
            {formik.errors.street1 && (
              <span style={{ fontSize: 15 }} className="errorMsg">
                {formik.errors.street1}
              </span>
            )}
          </FormRowCol>

          <FormRowCol label="Street 2">
            <div className={"divFlex divAlignItemsOnly"}>
              <div className={isMobile ? "my-10" : undefined}>
                {isEditMode && <Input {...formik.getFieldProps("street2")} />}
                {!isEditMode && (
                  <strong>
                    {formatBlankWithDefault(formik.values.street2, "", true)}
                  </strong>
                )}
              </div>
            </div>
          </FormRowCol>

          <FormRowCol label="City">
            <div className={"divFlex divAlignItemsOnly"}>
              <div className={isMobile ? "my-10" : undefined}>
                {isEditMode && (
                  <Input
                    status={formik.errors.city ? "error" : undefined}
                    {...formik.getFieldProps("city")}
                  />
                )}
                {!isEditMode && (
                  <strong>
                    {formatBlankWithDefault(formik.values.city, "", true)}
                  </strong>
                )}
              </div>
            </div>
          </FormRowCol>

          <FormRowCol label="State">
            <div className={"divFlex divAlignItemsOnly"}>
              <div className={isMobile ? "my-10" : undefined}>
                {isEditMode && (
                  <Input
                    status={formik.errors.state ? "error" : undefined}
                    {...formik.getFieldProps("state")}
                  />
                )}
                {!isEditMode && (
                  <strong>
                    {formatBlankWithDefault(formik.values.state, "", true)}
                  </strong>
                )}
              </div>
            </div>
          </FormRowCol>

          <FormRowCol label="Zipcode">
            <div className={"divFlex divAlignItemsOnly"}>
              <div className={isMobile ? "my-10" : undefined}>
                {isEditMode && (
                  <Input
                    maxLength={10}
                    status={formik.errors.zipCode ? "error" : undefined}
                    {...formik.getFieldProps("zipCode")}
                  />
                )}
                {!isEditMode && (
                  <strong>
                    {formatBlankWithDefault(formik.values.zipCode, "")}
                  </strong>
                )}
              </div>
            </div>
          </FormRowCol>

          <div className="divSpread mt-20">
            {isEditMode && (
              <Flex gap={20}>
                <Button
                  type="primary"
                  loading={isLoading}
                  onClick={formik.submitForm}
                  disabled={Object.keys(formik.errors).length > 0}
                  id="update-address"
                >
                  {" "}
                  Update
                </Button>

                <Button onClick={() => setIsEditMode(false)}>Cancel</Button>
              </Flex>
            )}
          </div>
        </form>
      </Paper>
    </div>
  );
}
