import { useEffect, useState } from "react";
import { useGetSession } from "../api/api.get";
import { getCurrencySymbol } from "../utils/functions";

export function useCurrency() {
  const { data: session } = useGetSession();
  const [currency, setCurrency] = useState<string>();

  useEffect(() => {
    if (session) {
      setCurrency(getCurrencySymbol(session.country_code));
    }
  }, [session]);

  return { currency };
}
