import LoadingButton from "@mui/lab/LoadingButton";
import { Switch } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDeleteCandidate, useDeleteUser } from "../../api/api.delete";
import {
  useGetCandidateByCandidateId,
  useGetUserByUsername,
  useGetUserVerificationDetailsByAdmin,
} from "../../api/api.get";
import { useResetPasswordAdmin, useUpdateSettings } from "../../api/api.update";
import ConfirmationDialog from "../../components/dialog/confirmation.dialog.component";
import FormRow from "../../components/forms/form.row.component";
import StatusMessageComponent from "../../components/status-message.component";
import { IStatusMsg } from "../../types/types";
import { ROUTES } from "../../utils/constants";
import IdVerificationSection from "./id-verification-section";
import moment from "moment";

export default function AdminViewCandidatePage() {
  const { cid } = useParams();
  const { data: candidate } = useGetCandidateByCandidateId(cid);

  const [status, setStatus] = useState<boolean>(true);
  const [entitlement, setEntitlement] = useState<string>();

  const { mutateAsync } = useUpdateSettings();

  const [statusMsg, setStatusMsg] = useState<IStatusMsg>();
  const [isResetPasswordLoading, setIsResetPasswordLoading] =
    useState<boolean>(false);
  const [deleteConf, showDeleteConf] = useState(false);

  const navigate = useNavigate();

  const { mutateAsync: deleteCandidateAsync } = useDeleteCandidate(cid);

  const handleDeletion = () => {
    deleteCandidateAsync(undefined)
      .then(() => {
        navigate(ROUTES.ADMIN_CANDIDATES);
      })
      .catch(() => {
        setStatusMsg({
          text: "Whoops! Something unexpected happened on delete.",
          type: "error",
        });
      });
  };

  return (
    <div className="body">
      <StatusMessageComponent
        message={statusMsg}
        onClose={() => setStatusMsg(undefined)}
      />

      <ConfirmationDialog
        open={deleteConf}
        title={"Delete Candidate"}
        subtitle="Are you sure?"
        onClose={() => showDeleteConf(false)}
        onProceed={handleDeletion}
      />

      <div style={{ margin: "0% 20%" }}>
        <div className="my-20">
          <Link to="/admin/users">Back</Link>
        </div>

        <div>
          <h3>{candidate?.username}</h3>
        </div>

        <div className="divColumn">
          <div className="">
            <FormRow label="Email" textSize="x-large">
              {candidate?.email}
            </FormRow>
          </div>
          <div className="">
            <FormRow label="Candidate Id" textSize="x-large">
              {candidate?.cid}
            </FormRow>
          </div>
          <hr />
          <div className="">
            <FormRow label="Registration Key" textSize="x-large">
              {candidate?.regkey}
            </FormRow>
          </div>
          <hr />

          <div className="divSpread">
            <FormRow label="Date" textSize="x-large">
              {moment(candidate?.created_at).format("DD MMM YYYY hh:mm A")}
            </FormRow>
          </div>
          <hr />
        </div>
        <FormRow label="">
          <div className="divColumn my-20">
            <div className="my-80 w-100">
              <LoadingButton
                variant="contained"
                color="error"
                loading={isResetPasswordLoading}
                onClick={() => showDeleteConf(true)}
                sx={{ width: "inherit" }}
              >
                {" "}
                Delete
              </LoadingButton>
            </div>
          </div>
        </FormRow>
      </div>
    </div>
  );
}
