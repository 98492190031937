import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { EXHUT_HUB_LOCAL_STORAGE_TOKEN } from "../utils/constants";

function _useDelete<T1, T2, T3>({
  path,
  keys,
  onSuccess,
}: {
  path: string;
  keys: (string | null | undefined)[];
  onSuccess?: () => void;
}) {
  return useMutation<T1, T2, T3>(
    keys,
    async (req) => {
      return axios.delete(`${process.env.REACT_APP_AUTH_BE_URL}/${path}`, {
        headers: {
          "Content-Type": "application/json",
          bearer: localStorage.getItem(EXHUT_HUB_LOCAL_STORAGE_TOKEN),
        },
      });
    },
    {
      onSuccess,
    }
  );
}

export function useDeleteJobPostReview(id: string) {
  return _useDelete({
    path: `job-post-review/${id}`,
    keys: ["job-post-reviews"],
  });
}

export function useDeleteMe() {
  return _useDelete({
    path: `users`,
    keys: ["users"],
  });
}

export function useDeleteUser(username?: string) {
  return _useDelete<null, null, string | undefined>({
    path: `admin/users?username=${username}`,
    keys: ["admin", "users"],
  });
}

export function useDeleteCandidate(cid?: string) {
  return _useDelete<null, null, string | undefined>({
    path: `admin/candidates/${cid}`,
    keys: ["admin", "candidates"],
  });
}

// used by admin
export function useDeleteAttachmentForUser(id?: string) {
  return _useDelete({
    path: `admin/attachments/${id}`,
    keys: ["users", "attachments", id],
  });
}

export function useDeleteIdvForUser(username?: string) {
  return _useDelete({
    path: `admin/users/verifications/identification?username=${username}`,
    keys: ["users", "verifications", "identification", username],
  });
}

export function useDeleteSupportTicket<T1, T2>() {
  return useMutation<T1, T2, string>(async (req) => {
    return axios.delete(`${process.env.REACT_APP_SUPPORT_URL}/support/${req}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem(EXHUT_HUB_LOCAL_STORAGE_TOKEN),
      },
    });
  });
}

export function useResetActivitiesForUser(username?: string) {
  return _useDelete({
    path: `admin/users/verifications/activities?username=${username}`,
    keys: ["users", "verifications", "activities", username],
  });
}

// ----------------exhut BE routes -----------------------------------------------

export function useRemoveCustomStatusMoneyTransfer(
  transferId: string,
  status: string
) {
  return _useDeleteBE({
    path: `admin/money-transfers/${transferId}/status?status=${status}`,
    keys: ["admin", "money-transfers", transferId],
  });
}

function _useDeleteBE<T1, T2, T3>({
  path,
  keys,
  onSuccess,
}: {
  path: string;
  keys: (string | null | undefined)[];
  onSuccess?: () => void;
}) {
  return useMutation<T1, T2, T3>(
    keys,
    async (req) => {
      return axios.delete(
        `${process.env.REACT_APP_EXHUT_BE_URL}/exhut-be/${path}`,
        {
          headers: {
            "Content-Type": "application/json",
            bearer: localStorage.getItem(EXHUT_HUB_LOCAL_STORAGE_TOKEN),
          },
        }
      );
    },
    {
      onSuccess,
    }
  );
}

export function useDeleteSubscriptions() {
  return _useDelete({
    path: `subscriptions`,
    keys: ["subscriptions"],
  });
}
