import { Select } from "antd";
import { countries } from "../../utils/constants";

export interface ICountryDropdownComponentProps {
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  value: any;
}

export default function CountryDropdown({
  onChange,
  value,
}: ICountryDropdownComponentProps) {
  return (
    <Select
      id="country-select"
      size="large"
      onChange={onChange}
      value={value}
      options={countries.map((country: string) => {
        return {
          value: country,
          label: country,
        };
      })}
    />
  );
}
