import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { IUser } from "../types/types";
import { EXHUT_HUB_LOCAL_STORAGE_TOKEN } from "../utils/constants";

export function usePostSession() {
  return useMutation<IUser, undefined>(["session"], async (req) => {
    return axios
      .get(`${process.env.REACT_APP_AUTH_BE_URL}/session`, {
        headers: {
          bearer: localStorage.getItem(EXHUT_HUB_LOCAL_STORAGE_TOKEN),
        },
      })
      .then((resp) => resp.data);
  });
}

export interface ICreateSubscriptionPayload {
  entitlement: number;
}

export interface ICreateSubscriptionResponse {
  approvalUrl: string;
}

export function useCreateSubscription() {
  return _usePost<ICreateSubscriptionResponse, any, ICreateSubscriptionPayload>(
    {
      path: `subscriptions`,
      keys: ["subscriptions"],
      resetKeys: ["subscriptions"],
    }
  );
}

interface IUpdateAddressVerificationForUser {
  username: string;
  addressVerified: boolean;
}

export function useUpdateAddressVerificationForUser() {
  return _usePost<any, any, IUpdateAddressVerificationForUser>({
    path: `admin/users/verifications/address`,
    keys: ["admin", "users", "verification", "address"],
    resetKeys: ["admin", "users", "verification"],
  });
}

interface IUpdateOfacVerificationForUser {
  username: string;
  ofacVerified: boolean;
}
export function useUpdateOfacVerificationForUser() {
  return _usePost<any, any, IUpdateOfacVerificationForUser>({
    path: `admin/users/verifications/ofac`,
    keys: ["admin", "users", "verification", "ofac"],
    resetKeys: ["admin", "users", "verification"],
  });
}

interface IUpdateAttachmentAsIdentity {
  username: string;
  attachmentId: string;
  action: "add" | "remove";
}

export function useUpdateAttachmentAsIdentity() {
  return _usePost<any, any, IUpdateAttachmentAsIdentity>({
    path: `admin/users/verifications/identification/attachments`,
    keys: ["admin", "users", "verification", "identification"],
    resetKeys: ["admin", "users", "verification"],
  });
}

interface IUpdateFraudStatusForUser {
  username: string;
  fraud: boolean;
  fraudDescription?: string;
}

export function useUpdateFraudStatusForUser() {
  return _usePost<any, any, IUpdateFraudStatusForUser>({
    path: `admin/users/verifications/fraud`,
    keys: ["admin", "users", "verification"],
    resetKeys: ["admin", "users", "verification"],
  });
}

interface IForgotPasswordValidateRequest {
  email: string;
  passcode: number;
  newPassword: string;
}

export function useForgotPasswordValidateMutation() {
  return _usePost<void, undefined, IForgotPasswordValidateRequest>({
    path: "auth/fgp/validate",
  });
}

interface IForgotPasswordRequest {
  email: string;
}

export function useForgotPasswordMutation() {
  return _usePost<void, undefined, IForgotPasswordRequest>({
    path: "auth/fgp",
  });
}

export interface IAuthenticatePayload {
  email: string;
  password: string;
}

export type IRegisterPayload = IAuthenticatePayload;

export type IAuthenticatResponse = IUser;

export function useRegisterMutation() {
  return _usePost<void, undefined, IRegisterPayload>({
    path: "register",
    keys: ["register"],
  });
}

export interface IRegisterSSOPayload {
  token: string;
}

export function useRegisterSSOMutation() {
  return useMutation<IAuthenticatResponse, undefined, IRegisterSSOPayload>(
    [],
    async (req) => {
      return axios
        .post(`${process.env.REACT_APP_AUTH_BE_URL}/register/sso`, req, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((resp) => resp.data);
    }
  );
}

export function useAuthenticate() {
  return useMutation<IAuthenticatResponse, undefined, IAuthenticatePayload>(
    [],
    async (req) => {
      return axios
        .post(`${process.env.REACT_APP_AUTH_BE_URL}/auth`, req, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((resp) => resp.data);
    }
  );
}

export interface IAuthenticateSSOPayload {
  token: string;
  type: "google";
}

export function useAuthenticateWithSSO() {
  return _usePost<IAuthenticatResponse, null, IAuthenticateSSOPayload>({
    path: `auth/sso`,
  });
}

export function usePostLogout() {
  return _usePost<void, null, void>({
    path: "logout",
  });
}

function _usePost<T1, T2, T3>({
  path,
  keys,
  resetKeys,
}: {
  path: string;
  keys?: string[];
  resetKeys?: string[];
}) {
  const _url = `${process.env.REACT_APP_AUTH_BE_URL}/${path}`;

  let headers: any = {
    "Content-Type": "application/json",
  };
  headers["Authorization"] = localStorage.getItem(
    EXHUT_HUB_LOCAL_STORAGE_TOKEN
  );

  const queryClient = useQueryClient();

  return useMutation<T1, T2, T3>({
    mutationFn: async (req) => {
      return axios
        .post(_url, req, {
          headers,
        })
        .then((resp) => resp.data);
    },
    mutationKey: keys,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: resetKeys }),
  });
}

// ---------------------------- SUPPORT -------------------------------------

interface ICreateSupportTicketPayload {
  title: string;
  description: string;
}

export function usePostCreateSupportTicket() {
  return useMutation<any, undefined, ICreateSupportTicketPayload>(
    ["support"],
    async (req) => {
      return axios
        .post(`${process.env.REACT_APP_SUPPORT_URL}/support`, req, {
          headers: {
            Authorization: localStorage.getItem(EXHUT_HUB_LOCAL_STORAGE_TOKEN),
          },
        })
        .then((resp) => resp.data);
    }
  );
}

interface IUpdateSupportTicketPayload {
  comments: string;
  status: string;
}

export function usePostUpdateTicket(id?: string) {
  return useMutation<any, undefined, IUpdateSupportTicketPayload>(
    ["support"],
    async (req) => {
      return axios
        .post(`${process.env.REACT_APP_SUPPORT_URL}/support/${id}`, req, {
          headers: {
            Authorization: localStorage.getItem(EXHUT_HUB_LOCAL_STORAGE_TOKEN),
          },
        })
        .then((resp) => resp.data);
    }
  );
}

// ------------------------------EXHUT BE---------------------------------------------

interface ICancelPayoutMutationPayload {
  description?: string;
  action: "CANCEL_PAYOUT" | "REFUND_ORDER";
}

export function useRefundOrderMutation(transferId?: string) {
  return _usePostExhutBackend<any, undefined, ICancelPayoutMutationPayload>({
    path: `admin/money-transfers/${transferId}/refund`,
    keys: ["admin", "money-transfers", transferId!!, "status"],
    resetKeys: ["admin", "money-transfers", transferId!!, "status"],
  });
}

export function useAddCustomStatusMoneyTransfer(transferId?: string) {
  return _usePostExhutBackend({
    path: `admin/money-transfers/${transferId}/status`,
    keys: ["admin", "money-transfers", transferId!!, "status"],
    resetKeys: ["admin", "money-transfers", transferId!!, "status"],
  });
}

function _usePostExhutBackend<T1, T2, T3>({
  path,
  keys,
  resetKeys,
}: {
  path: string;
  keys?: string[];
  resetKeys?: string[];
}) {
  const _url = `${process.env.REACT_APP_EXHUT_BE_URL}/exhut-be/${path}`;

  let headers: any = {
    "Content-Type": "application/json",
  };
  headers["bearer"] = localStorage.getItem(EXHUT_HUB_LOCAL_STORAGE_TOKEN);

  const queryClient = useQueryClient();

  return useMutation<T1, T2, T3>({
    mutationFn: async (req) => {
      return axios
        .post(_url, req, {
          headers,
        })
        .then((resp) => resp.data);
    },
    mutationKey: keys,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: resetKeys }),
  });
}
