import { Divider, Flex, Input, Modal, Slider } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useCurrency } from "../../../hooks/useCurrency";

export function RewardsCalculatorModal({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  const { currency } = useCurrency();
  const [itemPrice, setItemPrice] = useState<number>();
  const [makingCost, setMakingCost] = useState<number>();
  const [rewardsExpiry, setRewardsExpiry] = useState<number>(1);

  const [pointsProRate, setPointsProRate] = useState<number>(0);

  const userLocale = navigator.language || "en-US"; // Default to 'en-US' if the locale is unavailable
  const numberFormat = new Intl.NumberFormat(userLocale, {
    maximumFractionDigits: 2,
  });

  const points = useMemo(() => {
    if (itemPrice && pointsProRate) {
      return pointsProRate * itemPrice;
    } else {
      return 0;
    }
  }, [itemPrice, pointsProRate]);

  useEffect(() => {
    if (itemPrice) {
      setPointsProRate(10);
    } else {
      setPointsProRate(0);
    }
  }, [itemPrice]);

  const timesToGetFreeItem = useMemo(() => {
    if (itemPrice && points) {
      return Math.round(points / itemPrice);
    }
    return 0;
  }, [itemPrice, points]);

  const totalMakingCost = useMemo(() => {
    if (makingCost && timesToGetFreeItem) {
      return timesToGetFreeItem * (makingCost ?? 0);
    }
    return 0;
  }, [timesToGetFreeItem, makingCost]);

  const totalGross = useMemo(() => {
    if (itemPrice && timesToGetFreeItem) {
      return timesToGetFreeItem * (itemPrice ?? 0);
    }
    return 0;
  }, [timesToGetFreeItem, itemPrice]);

  return (
    <Modal
      title={"Rewards Calculator"}
      open={open}
      onClose={onClose}
      footer={null}
      onCancel={onClose}
    >
      <Flex vertical gap={30} className="p-20">
        <span className="infoMsg">
          Adjust the points slider and rewards expiration slider to see how much
          profit you could make per product per customer.
        </span>

        <div>
          <strong>Item Selling Price</strong>
          <Input
            prefix={currency}
            onChange={(e) => setItemPrice(+e.target.value)}
            value={itemPrice}
          />
        </div>

        <div>
          <strong>Cost To Make Item</strong>
          <Input
            prefix={currency}
            onChange={(e) => setMakingCost(+e.target.value)}
            value={makingCost}
          />
        </div>

        <div>
          <strong>Points</strong>

          <Flex className="divSpread">
            <Slider
              style={{ width: "80%" }}
              step={5}
              min={0}
              value={pointsProRate}
              onChange={(value: number) => setPointsProRate(value)}
            />
          </Flex>

          <span>{points}</span>
        </div>

        <div>
          <strong>Rewards Expiry (in months)</strong>

          <Flex className="divSpread">
            <Slider
              style={{ width: "80%" }}
              step={1}
              min={1}
              max={12}
              value={rewardsExpiry}
              onChange={(value: number) => setRewardsExpiry(value)}
            />
          </Flex>

          <span>{rewardsExpiry} months</span>
        </div>

        <Flex vertical>
          <strong># of purchases per customer to claim FREE item</strong>
          <span>{timesToGetFreeItem}</span>
        </Flex>

        <Flex vertical>
          <strong>Total from one customer</strong>
          <span>
            {currency}
            {totalGross}
          </span>
        </Flex>

        <Flex vertical>
          <strong>Total Making cost</strong>
          <span>{totalMakingCost}</span>
        </Flex>

        <Flex vertical>
          <strong>Total Profit From One Customer per month</strong>
          <h1>
            {currency}
            {numberFormat.format(
              (totalGross - totalMakingCost - (itemPrice ?? 0)) / rewardsExpiry
            )}
          </h1>
        </Flex>

        <Flex vertical>
          <strong>Total Profit From 10 Customers per month</strong>
          <h1>
            {currency}
            {numberFormat.format(
              ((totalGross - totalMakingCost - (itemPrice ?? 0)) /
                rewardsExpiry) *
                10
            )}
          </h1>
        </Flex>
      </Flex>
    </Modal>
  );
}

export function ProfitCalculatorModal({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  const [monthlyOrders, setMonthlyOrders] = useState<number>();
  const [pricePerOrder, setPricePerOrder] = useState<number>();
  const [repeatCustomers, setRepeatCustomers] = useState<number>(30);

  const userLocale = navigator.language || "en-US"; // Default to 'en-US' if the locale is unavailable
  const numberFormat = new Intl.NumberFormat(userLocale);

  const monthlyTotal = useMemo(() => {
    if (monthlyOrders && pricePerOrder) {
      return monthlyOrders * pricePerOrder;
    }

    return 0;
  }, [monthlyOrders, pricePerOrder]);

  const monthlyExtraOrders = useMemo(() => {
    if (monthlyOrders && repeatCustomers) {
      return (repeatCustomers / 100) * monthlyOrders;
    }

    return 0;
  }, [monthlyOrders, repeatCustomers]);

  const newMonthlyTotal = useMemo(() => {
    if (monthlyExtraOrders && monthlyOrders && pricePerOrder) {
      return (monthlyExtraOrders + monthlyOrders) * pricePerOrder;
    }
    return 0;
  }, [monthlyExtraOrders, monthlyOrders, pricePerOrder]);

  return (
    <Modal
      title={"Profit Calculator"}
      open={open}
      onClose={onClose}
      footer={null}
      onCancel={onClose}
    >
      <Flex vertical gap={30} className="p-20">
        <div className="divColumn bg-purple" style={{ padding: 10 }}>
          <strong style={{ color: "yellow" }}>Did you know?</strong>
          <span style={{ color: "white" }}>
            Customer Rewards boosts sales by 30% on average.
          </span>
        </div>

        <div>
          <strong>Average monthly orders</strong>
          <Input
            onChange={(e) => setMonthlyOrders(+e.target.value)}
            value={monthlyOrders}
          />
        </div>

        <div>
          <strong>Average price per order</strong>
          <Input
            onChange={(e) => setPricePerOrder(+e.target.value)}
            value={pricePerOrder}
          />
        </div>

        <Flex vertical>
          <strong>Monthly Total</strong>
          <span>{numberFormat.format(monthlyTotal)}</span>
        </Flex>

        <Flex vertical>
          <strong>Repeat Customers (%)</strong>
          <Input
            onChange={(e) => setRepeatCustomers(+e.target.value)}
            value={repeatCustomers}
          />
        </Flex>

        <Flex vertical>
          <strong className="successMsg">Extra Orders</strong>
          <span>{numberFormat.format(monthlyExtraOrders)}</span>
        </Flex>

        <Flex vertical>
          <strong>Total Orders</strong>
          <span>
            {numberFormat.format(monthlyExtraOrders + (monthlyOrders ?? 0))}
          </span>
        </Flex>

        <Flex vertical>
          <strong className="successMsg">New Monthly Total</strong>
          <span>{numberFormat.format(newMonthlyTotal)}</span>
        </Flex>

        <Flex vertical>
          <strong className="successMsg">Monthly Profit</strong>
          <span>{numberFormat.format(newMonthlyTotal - monthlyTotal)}</span>
        </Flex>

        <Flex vertical>
          <strong className="successMsg">Yearly Profit</strong>
          <span>
            {numberFormat.format((newMonthlyTotal - monthlyTotal) * 12)}
          </span>
        </Flex>
      </Flex>
    </Modal>
  );
}
