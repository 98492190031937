import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import {
  AdminIUser,
  AttachmentData,
  ICandidate,
  IJobComplaint,
  IMoneyTransfer,
  IMoneyTransferBatchPayoutDetails,
  IMoneyTransferFee,
  IMoneyTransferOrderCaptureDetails,
  IMoneyTransferPayout,
  IMoneyTransferPayoutItemDetails,
  IMoneyTransferStatus,
  IMoneyTransferWebHook,
  IProfile,
  ISupportTicket,
  ISupportTicketLifecycle,
  IUser,
  UserVerificationDetails,
  ISubscription,
} from "../types/types";
import { EXHUT_HUB_LOCAL_STORAGE_TOKEN } from "../utils/constants";

export function useGetSubscriptions() {
  return _useGet<ISubscription>(`subscriptions`, ["subscriptions"], true);
}

export function useGetSession(enabled: boolean = true) {
  return _useGet<IUser>("session", ["session"], enabled);
}

export function useGetProfile() {
  return _useGet<IProfile>(`users/profile`, ["profile"], true);
}

export function useGetUserByEmail(email?: string | null) {
  return _useGet<IUser[]>(
    `auth?email=${email}`,
    ["users", email],
    !!email,
    false
  );
}

export function useGetJobComplaints() {
  return _useGet<IJobComplaint[]>(`jobs-complaints`, ["jobs-complaints"]);
}

export function useGetUserByUsername(username?: string) {
  return _useGet<AdminIUser>(
    `admin/users?username=${username}`,
    ["admin", "users", username],
    !!username
  );
}

export function useGetUserVerificationDetailsByAdmin(username?: string) {
  return _useGet<UserVerificationDetails>(
    `admin/users/verifications?username=${username}`,
    ["admin", "users", "verification"],
    !!username
  );
}

export function useGetUserVerificationDetails() {
  return _useGet<UserVerificationDetails>(`id-verification`, [
    "id",
    "verification",
  ]);
}

export function useGetAllUsers() {
  return _useGet<AdminIUser[]>(`admin/users`, ["admin", "users"], true);
}

export function useGetAllCandidates() {
  return _useGet<ICandidate[]>(
    `admin/candidates`,
    ["admin", "candidates"],
    true
  );
}

export function useGetCandidateByCandidateId(id?: string) {
  return _useGet<ICandidate>(
    `admin/candidates/${id}`,
    ["admin", "candidates", id],
    !!id
  );
}

export function useGetAttachmentsForUser(username?: string) {
  return _useGet<AttachmentData[]>(
    `admin/attachments?username=${username}`,
    ["admin", "attachments", username],
    !!username
  );
}

interface IRewardsTotal {
  total: number;
}

export function useGetRewardsTotal() {
  return _useGetRewards<IRewardsTotal>(`rewards/total`, ["rewards"], true);
}

function _useGetRewards<T1>(
  path: string,
  keys: (string | undefined | null)[],
  enabled?: boolean,
  authenticated: boolean = true
) {
  return _useGet<T1>(
    path,
    keys,
    enabled,
    authenticated,
    process.env.REACT_APP_REWARDS_URL
  );
}

function _useGet<T1>(
  path: string,
  keys: (string | undefined | null)[],
  enabled?: boolean,
  authenticated: boolean = true,
  host?: string
) {
  let headers: any = {};

  const hostUrl = host ?? process.env.REACT_APP_AUTH_BE_URL;

  if (authenticated) {
    headers["bearer"] = localStorage.getItem(EXHUT_HUB_LOCAL_STORAGE_TOKEN);
    headers["authorization"] = localStorage.getItem(
      EXHUT_HUB_LOCAL_STORAGE_TOKEN
    );
  }
  return useQuery<T1, Error>(
    keys,
    async () => {
      return axios
        .get(`${hostUrl}/${path}`, {
          headers,
        })
        .then((resp) => resp.data);
    },
    {
      enabled,
      retry: 0,
    }
  );
}

export function useGetMySupportTickets() {
  return useQuery<ISupportTicket[]>(["support"], async (req) => {
    return axios
      .get(`${process.env.REACT_APP_SUPPORT_URL}/support`, {
        headers: {
          Authorization: localStorage.getItem(EXHUT_HUB_LOCAL_STORAGE_TOKEN),
        },
      })
      .then((resp) => resp.data);
  });
}

export function useGetMySupportTicketsById(id?: string) {
  return useQuery<ISupportTicket>(["support"], async (req) => {
    return axios
      .get(`${process.env.REACT_APP_SUPPORT_URL}/support/${id}`, {
        headers: {
          Authorization: localStorage.getItem(EXHUT_HUB_LOCAL_STORAGE_TOKEN),
        },
      })
      .then((resp) => resp.data);
  });
}

export function useGetTicketLifecycle(id?: string) {
  return useQuery<ISupportTicketLifecycle[]>(
    ["support", id],
    async (req) => {
      return axios
        .get(`${process.env.REACT_APP_SUPPORT_URL}/support/${id}/lifecycle`, {
          headers: {
            Authorization: localStorage.getItem(EXHUT_HUB_LOCAL_STORAGE_TOKEN),
          },
        })
        .then((resp) => resp.data);
    },
    { enabled: !!id }
  );
}

// --------------backend services ------------------------------------------

export function useGetAllMoneyTransfersForAdmin() {
  return _useGetExhutBackendService<IMoneyTransfer[]>(
    "admin/money-transfers",
    ["admin-money-transfers"],
    true
  );
}

export function useGetMoneyTransferByIdForAdmin(id?: string) {
  return _useGetExhutBackendService<IMoneyTransfer>(
    "admin/money-transfers/" + id,
    ["admin-money-transfers", id],
    !!id
  );
}

export function useGetMoneyTransferPayoutsForAdmin(transferId?: string) {
  return _useGetExhutBackendService<IMoneyTransferPayout>(
    "money-transfers/" + transferId + "/payouts",
    ["admin-money-transfers", transferId, "payouts"],
    !!transferId
  );
}

export function useGetMoneyTransferPayoutsItemDetailsForAdmin(
  transferId?: string
) {
  return _useGetExhutBackendService<IMoneyTransferPayoutItemDetails>(
    "admin/money-transfers/" + transferId + "/payouts-item-status",
    ["admin-money-transfers", transferId, "payouts-item-status"],
    !!transferId
  );
}

export function useGetMoneyTransferBatchPayoutDetailsForAdmin(
  transferId?: string
) {
  return _useGetExhutBackendService<IMoneyTransferBatchPayoutDetails>(
    "admin/money-transfers/" + transferId + "/batch-payouts-status",
    ["admin-money-transfers", transferId, "batch-payouts-status"],
    !!transferId
  );
}

export function useGetMoneyTransferFeesForAdmin(transferId?: string) {
  return _useGetExhutBackendService<IMoneyTransferFee[]>(
    "admin/money-transfers/" + transferId + "/fees",
    ["admin-money-transfers", transferId, "fees"],
    !!transferId
  );
}

export function useGetMoneyTransferOrderCaptureDetailsForAdmin(
  transferId?: string
) {
  return _useGetExhutBackendService<IMoneyTransferOrderCaptureDetails>(
    "admin/money-transfers/" + transferId + "/order-details",
    ["admin-money-transfers", transferId, "order-details"],
    !!transferId
  );
}

export function useGetMoneyTransferStatusesByIdForAdmin(id?: string) {
  return _useGetExhutBackendService<IMoneyTransferStatus[]>(
    "admin/money-transfers/" + id + "/status",
    ["admin-money-transfers", id, "status"],
    !!id
  );
}

export function useGetMoneyTransferWebHooks(orderId?: string) {
  return _useGetExhutBackendService<IMoneyTransferWebHook[]>(
    "admin/hooks/paypal/" + orderId,
    ["admin-money-transfers-hooks", orderId],
    !!orderId
  );
}

function _useGetExhutBackendService<T1>(
  path: string,
  keys: (string | undefined | null)[],
  enabled?: boolean
) {
  return _useGet<T1>(
    path,
    keys,
    enabled,
    true,
    `${process.env.REACT_APP_EXHUT_BE_URL}/exhut-be`
  );
}
