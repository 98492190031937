import { Button, Checkbox, Flex } from "antd";

import { Content } from "antd/es/layout/layout";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useCreateSubscription } from "../../../api/api";
import { useGetSession, useGetSubscriptions } from "../../../api/api.get";
import { useNotifications2 } from "../../../components/notifications/notification";
import {
  ISubscriptionPackage,
  SubscriptTierItemsCard,
} from "./subscriptions.page";

export default function SubscriptionsReadyPage({
  selectedPackage,
  onBack,
}: {
  selectedPackage: ISubscriptionPackage;
  onBack: () => void;
}) {
  const [acceptTerms, setAcceptTerms] = useState<boolean>(false);
  const { mutateAsync, isLoading: isCreatePending } = useCreateSubscription();

  const { notification, notifyError } = useNotifications2();

  const handleUpgrade = () => {
    mutateAsync({ entitlement: selectedPackage.value })
      .then((resp) => {
        window.location.href = resp.approvalUrl;
      })
      .catch(() => {
        notifyError("Whoops! Unable to process at the moment.");
      })
      .finally(() => {});
  };

  return (
    <div
      id="subscriptions--readycontainer"
      className="p-40 flex-1"
      style={{ minHeight: 600 }}
    >
      {notification}

      <div className={`divCenterAlign divColumn p-32`}>
        <div className="my-20">
          <Button type="link" onClick={onBack}>
            Back
          </Button>
        </div>

        <Content
          style={{
            boxShadow: "3px 1px 5px 5px #e6ddd6",
            minWidth: "40%",
            padding: 40,
            backgroundColor: "darkslateblue",
            color: "white",
          }}
        >
          <SubscriptTierItemsCard subPackage={selectedPackage} />

          <div className="my-20">
            <span className="smallText">
              If you are on your trial period, the subcription will begin after
              your trial expires.
            </span>

            <Flex gap={10} className="divCenterAlign divAlignItemsOnly my-20">
              <Checkbox
                checked={acceptTerms}
                onChange={() => setAcceptTerms(!acceptTerms)}
              />
              <span className="mediumText">
                By clicking this checkbox, I agree to the
                <a
                  className="mx-5"
                  href="https://help.expensehut.com/subscriptions"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  subscription policy
                </a>
                and{" "}
                <a
                  href="https://help.expensehut.com/terms"
                  target="_blank"
                  rel="noreferrer"
                >
                  terms and conditions
                </a>
                .
              </span>
            </Flex>

            <Flex vertical gap={10}>
              <Button
                disabled={!acceptTerms}
                style={{
                  backgroundColor: !acceptTerms ? "lightgrey" : "limegreen",
                  color: !acceptTerms ? "grey" : "white",
                }}
                type="default"
                loading={isCreatePending}
                onClick={() => {
                  handleUpgrade();
                }}
              >
                {"Upgrade"}
              </Button>

              <Button type="link" onClick={onBack} style={{ color: "wheat" }}>
                Cancel
              </Button>
            </Flex>

            <div className="my-20">
              <img
                src="https://www.paypalobjects.com/webstatic/mktg/logo/pp_cc_mark_37x23.jpg"
                alt="PayPal Logo"
              />
            </div>

            <div
              className="mt-20"
              style={{
                display: "flex",
                textAlign: "left",
                padding: "0px 40px",
              }}
            >
              <ul className="mediumText" style={{ listStyle: "number" }}>
                <li>
                  You will be redirected to Paypal website to complete the
                  transaction.
                </li>
                <li>
                  All subscriptions can be refunded within 14 days. Please see
                  <a
                    className="mx-5"
                    href="https://help.expensehut.com/refunds"
                    target="_blank"
                    rel="noreferrer"
                  >
                    refund policy{" "}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </Content>
      </div>
    </div>
  );
}
