import { useFormik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useAuthenticate } from "../../../api/api";
import { useUpdatePassword } from "../../../api/api.update";
import ErrorPanel from "../../../components/error.panel.component";
import StatusMessageComponent from "../../../components/status-message.component";
import { useLayoutContext } from "../../../providers/layout.provider";
import { IStatusMsg } from "../../../types/types";
import { Button, Flex, Input } from "antd";

export default function ChangePasswordPage() {
  const { isMobile } = useLayoutContext();
  const { mutateAsync, isLoading, isError } = useAuthenticate();
  const navigate = useNavigate();
  const [statusMsg, setStatusMsg] = useState<IStatusMsg | undefined>();
  const { mutateAsync: updatePasswordAsync } = useUpdatePassword();

  const formik = useFormik({
    initialValues: {
      password: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string().required("Please enter password."),
      newPassword: Yup.string().required("Please enter new password."),
      confirmPassword: Yup.string()
        .required("Please confirm new password.")
        .oneOf([Yup.ref("newPassword")], "Passwords do not match"),
    }),
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: async (values) => {
      updatePasswordAsync({
        newPassword: values.newPassword,
        password: values.password,
      })
        .then((resp) => {
          setStatusMsg({
            type: "success",
            text: "Password successfully updated.",
          });
        })
        .catch((e) => {
          if (e.response.status === 400) {
            setStatusMsg({
              type: "error",
              text: "Please verify your current password.",
            });
          } else {
            setStatusMsg({
              type: "error",
              text: "Whoops! Something went wrong. Please try again.",
            });
          }
        })
        .finally(() => formik.resetForm());
    },
  });

  return (
    <div className="body">
      <div className="divCenterAlign divColumn">
        <StatusMessageComponent
          message={statusMsg}
          onClose={() => setStatusMsg(undefined)}
        />

        <h2>Change Password</h2>

        <div
          className="mb-80"
          style={{
            backgroundColor: "#f4f7f8",
            flex: 1,
            border: "1px solid darkgrey",
            borderRadius: 10,
            padding: "10px 40px",
          }}
        >
          <form
            onSubmit={formik.handleSubmit}
            className="divColumn divCenterAlign px-20 pb-40"
          >
            <Flex vertical className="p-20" gap={30}>
              <Input.Password
                {...formik.getFieldProps("password")}
                status={!!formik.errors.password ? "error" : undefined}
                size="large"
                id="password"
                placeholder="Current Password..."
                htmlSize={30}
              />

              <Input.Password
                {...formik.getFieldProps("newPassword")}
                status={!!formik.errors.newPassword ? "error" : undefined}
                size="large"
                id="newPassword"
                placeholder="New Password..."
              />

              <Flex gap={10} vertical>
                <Input.Password
                  {...formik.getFieldProps("confirmPassword")}
                  status={!!formik.errors.confirmPassword ? "error" : undefined}
                  size="large"
                  id="confirmPassword"
                  placeholder="Confirm Password..."
                />
                {!!formik.errors.confirmPassword && (
                  <ErrorPanel message={formik.errors.confirmPassword} />
                )}
              </Flex>
            </Flex>

            <div style={{ height: 70 }} className="mx-20">
              <Button
                disabled={Object.keys(formik.errors).length > 0}
                loading={isLoading}
              >
                Submit
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
