import "./App.css";
import Main from "./Main";
import "./styles/button.styles.css";
import "./styles/table.styles.css";
import { ConfigProvider } from "antd";

const App = () => {
  return (
    <ConfigProvider
      theme={{ token: { colorPrimary: " rgba(122, 74, 255, 1) 0%," } }}
    >
      <Main />
    </ConfigProvider>
  );
};
export default App;
